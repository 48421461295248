import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectType, checkLogin, checkMode, changeWalletPage, changeLanguages, checkAlert, check_Error, checkGuide } from "../store";
import i18n from "../lang/i18n";
import { useTranslation } from "react-i18next";

import '../Pages/css/main2/style.css'
import '../Pages/css/main2/lightbox.css'
import '../Pages/css/main2/swiper-bundle.min.css'

function GOEXS_footer() {
    let dispatch = useDispatch();
    const navigate = useNavigate();

    // 언어변경
    const { t } = useTranslation();
    const onChangeLang = (e) => {
        i18n.changeLanguage(e);
        dispatch(changeLanguages(e));
    };

    const [lan, setLan] = useState("English")

    return (
        <footer style={{ textAlign: "left" }}>
            <div className="footer-area display-grid-area grid-colum-6 max-width-1640 grid-gab-16">
                <div className="item">
                    <h4>{t('footer_2')}</h4>
                    <ul className="margin-top-s">
                        <li><a href="#">{t('footer_3')}</a></li>
                        <li><a href="https://hb-goex.zendesk.com/hc/ko" target="_blank">{t('footer_4')}</a></li>
                        <li><a href="https://hb-goex.zendesk.com/hc/ko" target="_blank">{t('footer_5')}</a></li>
                        <li><a href="#">{t('footer_6')}</a></li>
                        <li><a href="#">{t('footer_7')}</a></li>
                    </ul>
                </div>
                <div className="item">
                    <h4>{t('footer_8')}</h4>
                    <ul className="margin-top-s">
                        <li><a href="#">{t('footer_9')}</a></li>
                        <li><a href="#">{t('footer_10')}</a></li>
                    </ul>
                </div>
                <div className="item">
                    <h4>{t('footer_11')}</h4>
                    <ul className="margin-top-s">
                        <li><a href="#">{t('footer_12')}</a></li>
                        <li><a href="#">{t('footer_13')}</a></li>
                    </ul>
                </div>
                <div className="item">
                    <h4>{t('footer_16')}</h4>
                    <ul className="sns-icon-box margin-top-s">
                        <li><a href="#"><img src="/img/images/icon/Layer_2.svg" alt="Facebook" /></a></li>
                        <li><a href="#"><img src="/img/images/icon/Layer_3.svg" alt="Youtube" /></a></li>
                        <li><a href="#"><img src="/img/images/icon/Layer_1.svg" alt="Twitter" /></a></li>
                    </ul>
                </div>
                <div className="item">
                    <h4>{t('footer_17')}</h4>
                    <ul className="language-box margin-top-s">
                        <li><a href="#" className="current-lang">{lan} <i class="bx bxs-down-arrow"></i></a>
                            <ul className="lang-select-list">
                                <li><a href="#" onClick={() => { onChangeLang("en-US"); setLan("English") }}>English</a></li>
                                <li><a href="#" onClick={() => { onChangeLang("ja-JP"); setLan("日本語") }}>日本語</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <a href="/"><img src="/img/images/GOEX_logo_dark.png" alt="goExs" /></a>
                    <p className="copyright margin-top-m">{t('footer_15')}</p>
                </div>
            </div>
        </footer>
    )
}

export default GOEXS_footer;