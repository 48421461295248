import './css/login.css'
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, check_Error } from '../store';
import Country from './country'
import { useTranslation } from "react-i18next";

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Login() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [btn, setBtn] = useState(0)
    const [eye, setEye] = useState(false);

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('82');
    const [tel, setTel] = useState('')
    const [password, setPassword] = useState("")
    const [checked, setChecked] = useState(true);

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value
        // const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const onChangeTel = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setTel(onlyNumber);
    }

    const onChangePassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setPassword(onlyNumber)
    }

    const onSubmit = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/login", {
            params: {
                id: btn === 0 ? email : tel,
                countryCode: btn === 0 ? "" : phone,
                password: password,
                type: btn === 0 ? "email" : "mobile",
            }
        }).then((response) => {
            if (response.data.code == '1000') {
                navigate('/otp', {
                    state: {
                        page: 1,
                        id: btn === 0 ? email : phone + tel,
                        userId: response.data.data.userId,
                        type: btn === 0 ? "email" : "mobile",
                        otp: response.data.data.otp,
                        loginCheck: checked
                    }
                })
            }
            else {
                alert(`${response.data.msg}`)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 27, "msg": error }))
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
                navigate('/')
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "RF-Rev│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, []);

    return (
        <div className={`regist ${darkname}`}>
            <div className='regist-box'>
                <div className={`regist-box-left ${darkname}`}>
                    <h4 className='regist-box-left-title' style={{color:"inherit"}}>{API_Address == "rf" ? "RF-Rev" : "goEx"} {t('login_0')}</h4>
                    <ul className='regist-box-left-ul'>
                        <li className={btn === 0 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(0)}>{t('login_1')}</li>
                        <li className={btn === 1 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(1)}>{t('login_2')}</li>
                    </ul>
                    <div className='regist-box-left-form'>
                        <div className='regist-box-left-form-1'>
                            {
                                btn === 0
                                    ?
                                    <div className='regist-box-left-form-1-1'>
                                        <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                            <input className='regist-box-left-form-1-input' type='text' value={email} onChange={onChangeEmail} placeholder={`${t('login_3')}`} />
                                        </div>
                                    </div>
                                    :
                                    <div className='regist-box-left-form-1-1'>
                                        <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                            <div className='regist-box-left-form-country'>
                                                <input type="search" className='regist-box-left-form-country-input' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                                <datalist id="list">
                                                    {
                                                        Country.map(function (a, i) {
                                                            return (
                                                                <option value={a.value}>{a.name} ({a.value})</option>
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                            </div>
                                            <input className='regist-box-left-form-1-input' type='text' value={tel} onChange={onChangeTel} placeholder={`${t('login_4')}`} />
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className='regist-box-left-form-1-1'>
                            <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                <input className='regist-box-left-form-1-input' type={eye ? 'text' : 'password'} value={password} onChange={onChangePassword} onKeyDown={handleKeyPress} placeholder={`${t('login_5')}`} />
                                {
                                    eye
                                        ?
                                        <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                        :
                                        <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='regist-box-left-form-4' onClick={() => onSubmit()}>{t('login_0')}</div>
                    <div className='regist-box-left-form-5'>
                        <label className='regist-box-left-form-3-label' >
                            <span className={checked ? 'regist-box-left-form-3-span1 checked' : 'regist-box-left-form-3-span1'} onClick={() => setChecked(checked => !checked)}></span>
                            <span className='regist-box-left-form-3-span2'>{t('login_20')}</span>
                        </label>
                        <Link to={`/regist`} className='regist-box-left-form-5-span'>{t('regist_0')}</Link>
                    </div>
                    <div className={`regist-box-left-form-hr ${darkname}`}></div>
                    <a href='' className='regist-box-left-form-6'>
                        <div className='regist-box-left-form-6-1' onClick={() => alert("Coming Soon")}>
                            <img src='/img/google_simbol.svg'></img>
                            <div className='regist-box-left-form-6-btn'>{t('login_7')}</div>
                        </div>
                    </a>
                </div>
                <div className='regist-box-right'>
                    <div className='regist-box-right-1'>
                        <div className='regist-box-right-1-1'>
                            <p className='regist-box-right-1-p'>{t('login_8')}</p>
                            <Swiper
                                modules={[Autoplay, Pagination, Navigation]}
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={true}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                className="mySwiper">
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">1</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_9')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_10')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">2</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_11')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_12')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">3</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_13')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_15')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">4</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_16')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_17')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="im_pt_marketing_wrap">
                                        <div className="im_pt_marketing_tit_wrap">
                                            <div className="im_pt_marketing_tit_num">5</div>
                                            <p className="im_pt_marketing_tit_desc">{t('login_18')}</p>
                                        </div>
                                        <div className="im_pt_marketing_txt_wrap">
                                            {t('login_19')}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;