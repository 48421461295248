import { configureStore, createSlice } from '@reduxjs/toolkit'
import { values } from 'lodash'


let API_Address = createSlice({
    name: 'API_Address',
    initialState: { value: "" },
    reducers: {
        checkAPI_Address(state, action) {
            let va = ""
            if (action.payload == "https://api.alp.kr") va = "rf"
            else if (action.payload == "https://api.coinrf.io") va = "dev"
            else va = "dev"
            state.value = va
        }
    }
})

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let check_KYC = createSlice({
    name: 'check_KYC',
    initialState: {},
    reducers: {
        changeKYC(state, action) {
            state.value = action.payload
        }
    }
})

let SelectType = createSlice({
    name: 'SelectType',
    initialState: { value: 0 },
    reducers: {
        checkSelectType(state, action) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                const newobj = {
                    value: obj.value,
                    expire: obj.expire,
                    type: action.payload === 0 ? "okx" : "binance",
                    login: obj.login
                }
                const newobjString = JSON.stringify(newobj);
                localStorage.setItem('token', newobjString)
            }
            state.value = action.payload
        }
    }
})

let Wallet_Page = createSlice({
    name: 'Wallet_Page',
    initialState: { value: -1 },
    reducers: {
        changeWalletPage(state, action) {
            state.value = action.payload
        }
    }
})

let Binance_coinS = createSlice({
    name: 'Binance_coinS',
    initialState: [],
    reducers: {
        Binance_addCoinS(state, action) {
            let num = 0;
            state.map(function (a, i) {
                if (a.symbol == action.payload.symbol) {
                    state[i] = action.payload
                    num = 1
                    // if (a.lastPrice != action.payload.lastPrice) state[i].check = true
                }
            })
            if (num == 0) state.push(action.payload)
            num = 0
        }
    }
})

let Binance_coinF = createSlice({
    name: 'Binance_coinF',
    initialState: [],
    reducers: {
        Binance_addCoinF(state, action) {
            let num = 0;
            state.map(function (a, i) {
                if (a.symbol == action.payload.symbol) {
                    state[i] = action.payload
                    num = 1
                    // if (a.lastPrice != action.payload.lastPrice) state[i].check = true
                }
            })
            if (num == 0) state.push(action.payload)
            num = 0
        }
    }
})

let OKX_coinS = createSlice({
    name: 'OKX_coinS',
    initialState: [],
    reducers: {
        OKX_addCoinS(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let OKX_coinF = createSlice({
    name: 'OKX_coinF',
    initialState: [],
    reducers: {
        OKX_addCoinF(state, action) {
            // let act = action.payload
            // for (let i = 0; i < act.length; i++) {
            //     for (let j = 0; j < state.length; j++) {
            //         if (act[i].symbol == state[j].symbol) {
            //             if (act[i].lastPrice != state[j].lastPrice) act[i].check = true
            //         }
            //     }
            // }

            return action.payload
        }
    }
})

let OKX_S_favorite = createSlice({
    name: 'OKX_S_favorite',
    initialState: [],
    reducers: {
        addCoin_OKX_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_OKX_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let OKX_F_favorite = createSlice({
    name: 'OKX_F_favorite',
    initialState: [],
    reducers: {
        addCoin_OKX_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_OKX_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_S_favorite = createSlice({
    name: 'Binance_S_favorite',
    initialState: [],
    reducers: {
        addCoin_Binance_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Binance_S_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_F_favorite = createSlice({
    name: 'Binance_F_favorite',
    initialState: [],
    reducers: {
        addCoin_Binance_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        },
        change_Binance_F_Favorite(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
            else {
                state.splice(num, 1)
            }
        }
    }
})

let Binance_position = createSlice({
    name: 'Binance_position',
    initialState: [],
    reducers: {
        change_Binance_position(state, action) {
            return action.payload;
        }
    }
})

let OKX_position = createSlice({
    name: 'OKX_position',
    initialState: [],
    reducers: {
        change_OKX_position(state, action) {
            return action.payload;
        }
    }
})
let Binance_liquidation = createSlice({
    name: 'Binance_liquidation',
    initialState: false,
    reducers: {
        change_Binance_liquidation(state, action) {
            let v = state
            return !v;
        }
    }
})
let OKX_liquidation = createSlice({
    name: 'OKX_liquidation',
    initialState: false,
    reducers: {
        change_OKX_liquidation(state, action) {
            let v = state
            return !v;
        }
    }
})
let OKX_Instruments = createSlice({
    name: 'OKX_Instruments',
    initialState: [],
    reducers: {
        check_OKX_Instruments(state, action) {
            return action.payload;
        }
    }
})

let Binance_ListenKey = createSlice({
    name: 'Binance_ListenKey',
    initialState: [],
    reducers: {
        change_Binance_ListenKey(state, action) {
            state.push(action.payload)
        }
    }
})

let Binance_wallet_spot = createSlice({
    name: 'Binance_wallet_spot',
    initialState: [],
    reducers: {
        change_Binance_wallet_spot(state, action) {
            return action.payload;
        }
    }
})

let Binance_wallet_futures = createSlice({
    name: 'Binance_wallet_futures',
    initialState: [],
    reducers: {
        change_Binance_wallet_futures(state, action) {
            return action.payload;
        }
    }
})
let Binance_openorder_futures = createSlice({
    name: 'Binance_openorder_futures',
    initialState: [],
    reducers: {
        change_Binance_openorder_futures(state, action) {
            return action.payload;
        }
    }
})
let OKX_wallet = createSlice({
    name: 'OKX_wallet',
    initialState: [],
    reducers: {
        change_OKX_wallet(state, action) {
            return action.payload;
        }
    }
})
let OKX_wallet_usdt = createSlice({
    name: 'OKX_wallet_usdt',
    initialState: [],
    reducers: {
        change_OKX_wallet_usdt(state, action) {
            return action.payload;
        }
    }
})
let OKX_wallet_availBal = createSlice({
    name: 'OKX_wallet_availBal',
    initialState: [],
    reducers: {
        change_OKX_wallet_availBal(state, action) {
            return action.payload;
        }
    }
})

let ImgCheck = createSlice({
    name: 'ImgCheck',
    initialState: [],
    reducers: {
        addImg(state, action) {
            let num = state.findIndex((a) => { return a === action.payload })
            if (num === -1) {
                state.push(action.payload)
            }
        }
    }
})

let checkspot = createSlice({
    name: 'checkspot',
    initialState: { value: false },
    reducers: {
        checkSpot(state, action) {
            state.value = action.payload
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: true },
    reducers: {
        checkMode(state, action) {
            localStorage.setItem('mode', action.payload ? "dark" : "light")
            state.value = action.payload
        }
    }
})

let Languages = createSlice({
    name: 'Languages',
    initialState: { value: "en-US" },
    reducers: {
        changeLanguages(state, action) {
            localStorage.setItem('language', action.payload)
            state.value = action.payload
        }
    }
})

let Alert = createSlice({
    name: 'Alert',
    initialState: ["OFF", "OFF", "OFF", "OFF"],
    reducers: {
        checkAlert(state, action) {
            let n = action.payload
            state[n.num] = n.value
            localStorage.setItem('alert', state)
        }
    }
})

let Error = createSlice({
    name: 'Error',
    initialState: { value: false },
    reducers: {
        check_Error(state, action) {
            // console.log(action.payload)
            state.value = action.payload
        }
    }
})

let Guide = createSlice({
    name: 'Guide',
    initialState: { value: false },
    reducers: {
        checkGuide(state, action) {
            state.value = action.payload
        }
    }
})

let OKX_timestamp = createSlice({
    name: 'OKX_timestamp',
    initialState: { value: "" },
    reducers: {
        checkOKX_timestamp(state, action) {
            state.value = action.payload
        }
    }
})

export let { checkAPI_Address } = API_Address.actions
export let { checkLogin } = login.actions
export let { changeKYC } = check_KYC.actions
export let { checkSelectType } = SelectType.actions
export let { changeWalletPage } = Wallet_Page.actions
export let { Binance_addCoinS } = Binance_coinS.actions
export let { Binance_addCoinF } = Binance_coinF.actions
export let { OKX_addCoinS } = OKX_coinS.actions
export let { OKX_addCoinF } = OKX_coinF.actions
export let { addCoin_OKX_S_Favorite, change_OKX_S_Favorite } = OKX_S_favorite.actions
export let { addCoin_OKX_F_Favorite, change_OKX_F_Favorite } = OKX_F_favorite.actions
export let { addCoin_Binance_S_Favorite, change_Binance_S_Favorite } = Binance_S_favorite.actions
export let { addCoin_Binance_F_Favorite, change_Binance_F_Favorite } = Binance_F_favorite.actions
export let { addImg } = ImgCheck.actions
export let { checkSpot } = checkspot.actions
export let { checkMode } = Darkmode.actions
export let { changeLanguages } = Languages.actions
export let { checkAlert } = Alert.actions
export let { change_Binance_position } = Binance_position.actions
export let { change_OKX_position } = OKX_position.actions
export let { change_Binance_liquidation } = Binance_liquidation.actions
export let { change_OKX_liquidation } = OKX_liquidation.actions
export let { change_Binance_ListenKey } = Binance_ListenKey.actions
export let { change_Binance_wallet_spot } = Binance_wallet_spot.actions
export let { change_Binance_wallet_futures } = Binance_wallet_futures.actions
export let { change_Binance_openorder_futures } = Binance_openorder_futures.actions
export let { change_OKX_wallet } = OKX_wallet.actions
export let { change_OKX_wallet_availBal } = OKX_wallet_availBal.actions
export let { change_OKX_wallet_usdt } = OKX_wallet_usdt.actions
export let { check_OKX_Instruments } = OKX_Instruments.actions
export let { check_Error } = Error.actions
export let { checkGuide } = Guide.actions
export let { checkOKX_timestamp } = OKX_timestamp.actions

export default configureStore({
    reducer: {
        API_Address: API_Address.reducer,
        login: login.reducer,
        check_KYC: check_KYC.reducer,
        SelectType: SelectType.reducer,
        Wallet_Page: Wallet_Page.reducer,
        Binance_coinS: Binance_coinS.reducer,
        Binance_coinF: Binance_coinF.reducer,
        OKX_coinS: OKX_coinS.reducer,
        OKX_coinF: OKX_coinF.reducer,
        OKX_S_favorite: OKX_S_favorite.reducer,
        OKX_F_favorite: OKX_F_favorite.reducer,
        Binance_S_favorite: Binance_S_favorite.reducer,
        Binance_F_favorite: Binance_F_favorite.reducer,
        ImgCheck: ImgCheck.reducer,
        checkspot: checkspot.reducer,
        Darkmode: Darkmode.reducer,
        Languages: Languages.reducer,
        Alert: Alert.reducer,
        Binance_position: Binance_position.reducer,
        OKX_position: OKX_position.reducer,
        Binance_liquidation: Binance_liquidation.reducer,
        OKX_liquidation: OKX_liquidation.reducer,
        Binance_ListenKey: Binance_ListenKey.reducer,
        Binance_wallet_spot: Binance_wallet_spot.reducer,
        Binance_wallet_futures: Binance_wallet_futures.reducer,
        Binance_openorder_futures: Binance_openorder_futures.reducer,
        OKX_wallet: OKX_wallet.reducer,
        OKX_wallet_availBal: OKX_wallet_availBal.reducer,
        OKX_wallet_usdt: OKX_wallet_usdt.reducer,
        OKX_Instruments: OKX_Instruments.reducer,
        Error: Error.reducer,
        Guide: Guide.reducer,
        OKX_timestamp: OKX_timestamp.reducer
    }
}) 