import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import '../../css/main2/style.css'
import '../../css/main2/lightbox.css'
import '../../css/main2/swiper-bundle.min.css'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin } from '../../../store';
import { useTranslation } from "react-i18next";

let arr_topTrade5 = []
let arr_topPercentUp5 = []
let arr_topPercentDown5 = []
let arr_topPrice5 = []

function Main2() {
    let login = useSelector((state) => { return state.login.value; });
    const { t } = useTranslation();
    let BinanceF = useSelector((state) => { return state.Binance_coinF });

    const [text, setText] = useState("")

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const onChangeText = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    useEffect(() => {
        document.title = "goEx│One-Stop Trading Platform - Crypto Trading Universe";
    }, []);

    let topTrade5 = BinanceF ? [...BinanceF] : [];
    let topPercentUp5 = BinanceF ? [...BinanceF] : [];
    let topPercentDown5 = BinanceF ? [...BinanceF] : [];
    let topPrice5 = BinanceF ? [...BinanceF] : [];

    topTrade5.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    topPercentUp5.sort((a, b) => {
        if (a.priceChangePercent < b.priceChangePercent) return 1;
        if (a.priceChangePercent > b.priceChangePercent) return -1;
    });
    topPercentDown5.sort((a, b) => {
        if (a.priceChangePercent > b.priceChangePercent) return 1;
        if (a.priceChangePercent < b.priceChangePercent) return -1;
    });
    topPrice5.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));

    arr_topTrade5 = topTrade5.splice(0, 5)
    arr_topPercentUp5 = topPercentUp5.splice(0, 5)
    arr_topPercentDown5 = topPercentDown5.splice(0, 5)
    arr_topPrice5 = topPrice5.splice(0, 5)

    const onRegister = () => {
        navigate('/regist', {
            state: {
                id: text,
                type: isNumericString(text) ? 1 : 0
            }
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onRegister()
        }
    };

    // Scroll ani
    const anidivs = document.querySelectorAll('.anidiv')
    window.addEventListener('scroll', checkBoxes)
    checkBoxes()

    function checkBoxes() {
        const triggerBottom = window.innerHeight / 1.3
        anidivs.forEach(box => {
            const boxTop = box.getBoundingClientRect().top
            if (boxTop < triggerBottom) {
                box.classList.add('show')
            } else {
                box.classList.remove('show')
            }
        })
    }

    return (
        <div className='main-body' style={{ textAlign: "left" }}>
            <div className="wrap" style={{ paddingTop: "64px" }}>
                <header className="headcopy max-width-1640 anidiv">
                    <h2>{t('main_0')} <br />
                        {t('main_1')}</h2>
                    <p className="margin-top-s anidiv">{t('main_2')} <br />
                        {t('main_3')} <br className="hide-up-540px" />
                        {t('main_4')}</p>
                    <div className="headcopy-join-area margin-top-xl">
                        <input type='text' placeholder={`${t('main_5')}`} value={text} onChange={onChangeText} onKeyDown={handleKeyPress} />
                        <button onClick={() => onRegister()}>{t('main_6')}</button>
                    </div>
                </header>
                <section className="reserve max-width-1640">
                    <h2 className="text-align-center anidiv">{t('main_7')}</h2>
                    <div className="display-grid-area grid-colum-3 margin-top-l">
                        <div className="item anidiv">
                            <h3 className="text-align-center">{t('main_8')}</h3>
                        </div>
                        <div className="item anidiv">
                            <h3 className="text-align-center">{t('main_9')}</h3>
                        </div>
                        <div className="item anidiv">
                            <h3 className="text-align-center">{t('main_10')}</h3>
                        </div>
                    </div>
                </section>
                <section className="yourway display-grid-area grid-colum-4 max-width-1640 grid-gab-16">
                    <div className="item anidiv">
                        <h2>{t('main_11')} <br className="hide-down-540px" />
                            {t('main_12')} <br />
                            {t('main_13')} <br className="hide-down-540px" />
                            {t('main_14')}
                        </h2>
                        <Link to={`/market`}><button className="btn margin-top-l">{t('main_15')} <i class="bx bxs-right-arrow"></i></button></Link>
                    </div>
                    <div className="item anidiv">
                        <h3>{t('main_16')}</h3>
                        <div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/BTC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">BTCUSDT</div>
                                        <div className="main1-list-item-1-3">67000</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/ETC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">ETCUSDT</div>
                                        <div className="main1-list-item-1-3">25.3</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/XRP.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">XRPUSDT</div>
                                        <div className="main1-list-item-1-3">0.4888</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item anidiv">
                        <h3>{t('main_17')}</h3>
                        <div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/BTC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">BTCUSDT</div>
                                        <div className="main1-list-item-1-3">67000</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/ETC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">ETCUSDT</div>
                                        <div className="main1-list-item-1-3">25.3</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/XRP.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">XRPUSDT</div>
                                        <div className="main1-list-item-1-3">0.4888</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item anidiv">
                        <h3>{t('main_18')}</h3>
                        <div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/BTC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">BTCUSDT</div>
                                        <div className="main1-list-item-1-3">67000</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/ETC.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">ETCUSDT</div>
                                        <div className="main1-list-item-1-3">25.3</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 red">-19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src={"/img/coin/XRP.png"} style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">XRPUSDT</div>
                                        <div className="main1-list-item-1-3">0.4888</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main1-list-item">
                                <div className="main1-list-item-1">
                                    <img src="https://www.goexs.com/img/coin/OM.png" style={{ width: '48px' }} />
                                    <div className="main1-list-item-1-1">
                                        <div className="main1-list-item-1-2">OMUSDT</div>
                                        <div className="main1-list-item-1-3">0.86585</div>
                                    </div>
                                </div>
                                <div className="main1-list-item-2">
                                    <div className="main1-list-item-1-4 green">19.58%</div>
                                    <div className="main1-list-item-1-5">
                                        <span className="main1-list-item-1-5-1">VOL</span>
                                        <span className="main1-list-item-1-5-2">449.43M</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="provide">
                    <h2 className="text-align-center anidiv">{t('main_19')}</h2>
                    <div className="display-grid-area grid-colum-2 grid-gab-16 margin-top-l">
                        <div className="item">
                            <h3>{t('main_20')}</h3>
                            <img src="/img/images/Binance_Logo.svg" width="32" height="32" alt="Binance" className="margin-top-s" />
                            <p>{t('main_21')}<br />
                                <span className="notice-color bold-text">{t('main_22')}</span> {t('main_23')}</p>
                            <img src="/img/images/okx.svg" width="32" height="32" alt="okx" className="margin-top-s" />
                            <p>{t('main_24')}<br />
                                <span className="notice-color bold-text">{t('main_25')}</span> {t('main_26')}</p>
                            <button className="btn left-pos">{t('main_15')} <i class="bx bxs-right-arrow"></i></button>
                        </div>
                        <div className="item">
                            <h3>{t('main_27')}</h3>
                            <img src="/img/images/Binance_Logo.svg" width="32" height="32" alt="Binance" className="margin-top-s" />
                            <p>{t('main_28')}<br />
                                <span className="notice-color bold-text">{t('main_22')}</span> {t('main_23')}</p>
                            <img src="/img/images/okx.svg" width="32" height="32" alt="okx" className="margin-top-s" />
                            <p>{t('main_29')}<br />
                                <span className="notice-color bold-text">{t('main_25')}</span> {t('main_26')}</p>
                            <button className="btn right-pos">{t('main_15')} <i class="bx bxs-right-arrow"></i></button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

function isNumericString(str) {
    return !isNaN(str);
}

export default Main2;