import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faGlobe } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectType, checkLogin, checkMode, changeWalletPage, changeLanguages, checkAlert, check_Error, checkGuide } from "../store";
import base64 from "base-64";
import i18n from "../lang/i18n";
import { useTranslation } from "react-i18next";

import '../Pages/css/main2/style.css'
import '../Pages/css/main2/lightbox.css'
import '../Pages/css/main2/swiper-bundle.min.css'

let intervalId;
let login_cnt = 0;

function GOEXS_header() {
    let login = useSelector((state) => { return state.login.value; });
    let dark = useSelector((state) => { return state.Darkmode.value; });
    let Error = useSelector((state) => { return state.Error.value; });

    const [toggle, setToggle] = useState(false)

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const onChangeLang = (e) => {
        i18n.changeLanguage(e);
        dispatch(changeLanguages(e));
    };

    useEffect(() => {
        const objString = localStorage.getItem("language");
        if (objString != null) {
            i18n.changeLanguage(objString);
            dispatch(changeLanguages(objString));
        }
    }, []);

    const [lan, setLan] = useState("en")

    useEffect(() => {
        if (Error && Error.bol) {
            if (window.confirm(`[No.${Error.no}]Error, Please Refresh (${Error.msg})`)) {
                window.location.reload(true);
            }
        }
    }, [Error]);

    useEffect(() => {
        if (login_cnt > 0) {
            let objString = localStorage.getItem("token");
            if (objString != null) {
                let obj = JSON.parse(objString);
                let token = obj.value;
                let payload = token.substring(
                    token.indexOf(".") + 1,
                    token.lastIndexOf(".")
                );
                let dec = base64.decode(payload);

                let userId = JSON.parse(dec).userId;
                if (userId != 1 && userId != 90) {
                    LoginCheck(token);

                    intervalId = setInterval(() => {
                        LoginCheck(token);
                    }, 1000 * 30);

                    return () => clearInterval(intervalId);
                }
            }
        }
        login_cnt += 1;
    }, [login]);

    const LoginCheck = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/account/user/login/check`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (!response.data) {
                alert(`${t("message_21")}`);
                clearInterval(intervalId);
                localStorage.removeItem("token");
                dispatch(checkLogin(false));
                navigate("/login");
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 18 }));
        });
    };

    // Dark mode change
    const toggleDarkMode = () => {
        if (document.body.classList.contains("dark-mode")) {
            dispatch(checkMode(false));
            document.documentElement.setAttribute("color-scheme", "light");
            document.body.classList.remove("dark-mode");
        } else {
            dispatch(checkMode(true));
            document.documentElement.setAttribute("color-scheme", "dark");
            document.body.classList.add("dark-mode");
        }

        document.body.classList.toggle("dark-mode");
    }

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (obj.type === "okx") {
                dispatch(checkSelectType(0));
            }
            else {
                dispatch(checkSelectType(1));
            }
        }
        const objString1 = localStorage.getItem("mode");
        if (objString1 != null) {
            if (objString1 === "light") {
                dispatch(checkMode(false));
                document.documentElement.setAttribute("color-scheme", "light");
                document.body.classList.remove("dark-mode");
            }
            else {
                dispatch(checkMode(true));
                document.documentElement.setAttribute("color-scheme", "dark");
                document.body.classList.add("dark-mode");
            }
        }
        else {
            dispatch(checkMode(true));
            document.documentElement.setAttribute("color-scheme", "dark");
            document.body.classList.add("dark-mode");
        }
    }, [dark]);

    useEffect(() => {
        let objString = localStorage.getItem("alert");
        if (objString != null) {
            let arr = objString.split(",");
            for (let i = 0; i < arr.length; i++) {
                dispatch(checkAlert({ num: i, value: arr[i] }));
            }
        }
    }, []);

    const Logout = () => {
        if (window.confirm("Are you sure you want to log out?")) {
            clearInterval(intervalId);
            localStorage.removeItem("token");
            localStorage.removeItem("guide");
            dispatch(checkLogin(false));
            dispatch(checkGuide(false));
            navigate("/");
        }
    };

    return (
        <nav style={{ position: "absolute", zIndex: "1000", width: "100%" }}>
            <div className="nav-wrap max-width-1640">
                <div className="menu">
                    <Link to={`/`}>
                        <h1 className="logo">goExs</h1>
                    </Link>
                    <ul className="category">
                        <li><Link to={'/market'}>{t('header_0')} <i className="bx bxs-down-arrow"></i></Link>
                            <ul className="submenu">
                                <li><Link to={'/market'} ><i className='bx bx-list-ul'></i>
                                    <span>{t('header_1')}<br />
                                        <small>{t('header_2')}</small></span></Link></li>
                            </ul>
                        </li>
                        <li><Link>{t('header_3')} <i className="bx bxs-down-arrow"></i></Link>
                            <ul className="submenu">
                                <li><Link to={'/spot/binance/BTCUSDT'} ><img src="/img/images/Binance_Logo.svg" width="26" height="26" alt="Binance" />
                                    <span>{t('header_4')}<br />
                                        <small>{t('header_5')}</small></span></Link></li>
                                <li><Link to={'/spot/okx/BTC-USDT'} ><img src="/img/images/okx.svg" width="26" height="26" alt="okx" className="svg-black" />
                                    <span>{t('header_6')}<br />
                                        <small>{t('header_7')}</small></span></Link></li>
                            </ul>
                        </li>
                        <li><Link>{t('header_8')} <i className="bx bxs-down-arrow"></i></Link>
                            <ul className="submenu">
                                <li><Link to={'/futures/binance/BTCUSDT'} ><img src="/img/images/Binance_Logo.svg" width="26" height="26" alt="Binance" />
                                    <span>{t('header_9')}<br />
                                        <small>{t('header_5')}</small></span></Link></li>
                                <li><Link to={'/futures/okx/BTC-USDT-SWAP'} ><img src="/img/images/okx.svg" width="26" height="26" alt="okx" className="svg-black" />
                                    <span>{t('header_13')}<br />
                                        <small>{t('header_7')}</small></span></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="personal">
                    {
                        login
                            ?
                            <ul className="after-login">
                                <li><Link to={`/wallet`} state={{ num: 0 }} className="btn-circle"><i className="bx bxs-wallet"></i></Link>
                                    <span>{t('header_28')}</span>
                                    <ul className="submenu">
                                        <li><Link to={`/wallet`} state={{ num: 0 }}><i className="bx bxs-wallet"></i> <span>{t('header_29')}<br />
                                            <small>{t('header_30')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} className="warning-color" state={{ num: 1 }}><i className="bx bx-money-withdraw"></i> <span>{t('header_31')}<br />
                                            <small>{t('header_32')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} state={{ num: 2 }}><i className="bx bx-minus-back"></i> <span>{t('header_33')}<br />
                                            <small>{t('header_34')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} state={{ num: 3 }}><i className="bx bxs-wallet"></i> <span>{t('header_35')}<br />
                                            <small>{t('header_36')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} state={{ num: 4 }}><i className="bx bx-transfer-alt"></i> <span>{t('header_37')}<br />
                                            <small>{t('header_38')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} state={{ num: 5 }}><i className="bx bx-sync"></i> <span>{t('header_41')}<br />
                                            <small>{t('header_42')}</small></span></Link></li>
                                        <li><Link to={`/wallet`} state={{ num: 6 }}><i className="bx bxs-gift"></i> <span>{t('header_39')}<br />
                                            <small>{t('header_40')}</small></span></Link></li>
                                    </ul>
                                </li>
                                <li className="none-submenu"><Link to={`/wallet`} state={{ num: 1 }} className="btn-circle warning-bg-color"><i className="bx bx-money-withdraw" ></i></Link>
                                    <span>{t('header_31')}</span>
                                </li>
                                <li><Link to={`/history`} state={{ num: 0 }} className="btn-circle"><i className="bx bx-list-ul"></i></Link>
                                    <span>{t('header_15')}</span>
                                    <ul className="submenu">
                                        <li><Link to={`/history`} state={{ num: 0 }}><i className="bx bxs-notepad"></i> <span>{t('header_16')}<br />
                                            <small>{t('header_17')}</small></span></Link></li>
                                        <li><Link to={`/history`} state={{ num: 1 }}><i className="bx bxs-food-menu"></i> <span>{t('header_18')}<br />
                                            <small>{t('header_19')}</small></span></Link></li>
                                        <li><Link to={`/history`} state={{ num: 2 }}><i className="bx bx-notepad"></i> <span>{t('header_20')}<br />
                                            <small>{t('header_21')}</small></span></Link></li>
                                        <li><Link to={`/history`} state={{ num: 3 }}><i className="bx bx-food-menu"></i> <span>{t('header_22')}<br />
                                            <small>{t('header_23')}</small></span></Link></li>
                                        <li><Link to={`/history`} state={{ num: 4 }}><i className="bx bx-history"></i> <span>{t('header_24')}<br />
                                            <small>{t('header_25')}</small></span></Link></li>
                                    </ul>
                                </li>
                                <li><Link to={`/dashboard`} state={{ num: 0 }} className="btn-circle"><i className="bx bxs-user"></i></Link>
                                    <span>{t('header_43')}</span>
                                    <ul className="submenu">
                                        <li><Link to={`/dashboard`} state={{ num: 0 }}><i className="bx bxs-user"></i> <span>{t('header_44')}<br />
                                            <small>{t('header_45')}</small></span></Link></li>
                                        <li><Link to={`/dashboard`} state={{ num: 1 }}><i className="bx bx-shield-quarter"></i> <span>{t('header_46')}<br />
                                            <small>{t('header_47')}</small></span></Link></li>
                                        <li><Link to={`/dashboard`} state={{ num: 2 }}><i className="bx bxs-purchase-tag-alt"></i> <span>{t('header_48')}<br />
                                            <small>{t('header_49')}</small></span></Link></li>
                                        <li><Link to={`/dashboard`} state={{ num: 3 }}><i className="bx bx-purchase-tag-alt"></i> <span>{t('header_50')}<br />
                                            <small>{t('header_51')}</small></span></Link></li>
                                    </ul>
                                </li>
                                <li onClick={() => Logout()}><Link className="noBG"><i className="bx bx-log-out"></i></Link></li>
                            </ul>
                            :
                            <div className="login-join">
                                <Link to={`/login`}>{t('header_55')}</Link>
                                <Link to={`/regist`} className="btn-join">{t('header_56')}</Link>
                            </div>
                    }
                    <ul className="lang-mode">
                        <li className="lang-select"><Link><i className="bx bx-globe"></i></Link>
                            <ul className="submenu">
                                <li><Link onClick={() => { onChangeLang("en-US") }}><img src="/img/images/icon/united-states.svg" width="24" height="24" alt="English" /></Link></li>
                                <li><Link onClick={() => { onChangeLang("ja-JP") }}><img src="/img/images/icon/japan.svg" width="24" height="24" alt="日本語" /></Link></li>
                            </ul>
                        </li>
                        <li className="mode-select"><Link onClick={() => toggleDarkMode()}>Dark mode</Link></li>
                    </ul>
                    <div className="side-nav-btn" onClick={() => setToggle(true)}><i className="bx bx-menu"></i><span>nav open</span></div>
                </div>
            </div >
            <nav className={`sp-nav ${toggle ? "show-nav" : ""}`}>
                <div className="menu">
                    <h2 className="logo">goExs</h2>
                    <ul className="category">
                        <li><Link to={'/market'} onClick={() => setToggle(false)}>{t('header_0')}</Link></li>
                        <li><Link>{t('header_3')}</Link>
                            <ul className="submenu">
                                <li><Link to={'/spot/binance/BTCUSDT'} onClick={() => setToggle(false)}>{t('header_4')}</Link></li>
                                <li><Link to={'/spot/okx/BTC-USDT'} onClick={() => setToggle(false)}>{t('header_6')}</Link></li>
                            </ul>
                        </li>
                        <li><Link>{t('header_8')}</Link>
                            <ul className="submenu">
                                <li><Link to={'/futures/binance/BTCUSDT'} onClick={() => setToggle(false)}>{t('header_9')}</Link></li>
                                <li><Link to={'/futures/okx/BTC-USDT-SWAP'} onClick={() => setToggle(false)}>{t('header_13')}</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="personal">
                    {
                        login
                            ?
                            <ul className="after-login">
                                <li><Link to={`/wallet`} state={{ num: 0 }} className="btn-circle"><i className="bx bxs-wallet"></i></Link>
                                    <span>{t('header_28')}</span>
                                </li>
                                <li className="none-submenu"><Link to={`/wallet`} state={{ num: 1 }} className="btn-circle warning-bg-color"><i className="bx bx-money-withdraw" ></i></Link>
                                    <span>{t('header_31')}</span>
                                </li>
                                <li><Link to={`/history`} state={{ num: 0 }} className="btn-circle"><i className="bx bx-list-ul"></i></Link>
                                    <span>{t('header_15')}</span>
                                </li>
                                <li><Link to={`/dashboard`} state={{ num: 0 }} className="btn-circle"><i className="bx bxs-user"></i></Link>
                                    <span>{t('header_43')}</span>
                                </li>
                                <li onClick={() => Logout()}><Link  className="noBG"><i className="bx bx-log-out"></i></Link></li>
                            </ul>
                            :
                            <div className="login-join">
                                <Link to={`/login`} className="btn-join">{t('header_56')}</Link>
                                <Link to={`/regist`} className="btn-default">{t('header_55')}</Link>
                            </div>
                    }
                    <ul className="lang-mode">
                        <li className="lang-select">
                            <ul className="submenu">
                                <li><Link onClick={() => { onChangeLang("en-US"); setLan("en"); setToggle(false) }} className={`${lan == "en" ? "active" : ""}`}><img src="/img/images/icon/united-states.svg" width="24" height="24" alt="English" /> English</Link></li>
                                <li><Link onClick={() => { onChangeLang("ja-JP"); setLan("ja"); setToggle(false) }} className={`${lan == "ja" ? "active" : ""}`}><img src="/img/images/icon/japan.svg" width="24" height="24" alt="日本語" /> 日本語</Link></li>
                            </ul>
                        </li>
                        <li className="mode-select"><Link onClick={() => { toggleDarkMode(); setToggle(false) }}>Dark mode</Link></li>
                    </ul>
                </div>
                <div className="close-btn" onClick={() => setToggle(false)}><i className="bx bx-x"></i></div>
            </nav>
            <div className={`mask ${toggle ? "show-nav" : ""}`}></div>
        </nav >
    )
}



export default GOEXS_header;