import '../css/wallet.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage, check_Error } from '../../store';
import AOS from "aos";
import base64 from 'base-64';
import IMG from "../img"
import Message from '../message/message';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../common/function"

import { Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import Binance_Overview from "./Bwallet/bn_overview"
import Binance_Deposit from "./Bwallet/bn_deposit"
import Binance_Withdrawal from './Bwallet/bn_withdraw';
import Binance_Exchange from './Bwallet/bn_exchange';
import Binance_Transfer from './Bwallet/bn_transfer';
import Binance_Gift from './Bwallet/bn_gift';
import Binance_QuickWithdrawal from './Bwallet/bn_quickWithdrawl';

import Common_Deposit from '../common/C_wallet/c_deposit';
import Common_Withdrawal from '../common/C_wallet/c_withdraw';
import Common_Exchange from '../common/C_wallet/c_exchange';
import Common_Transfer from '../common/C_wallet/c_transfer';
import Common_Gift from '../common/C_wallet/c_gift';


function BWallet() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let BinanceS = useSelector((state) => { return state.Binance_coinS });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    let Wallet_Page = useSelector((state) => { return state.Wallet_Page.value });
    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });
    let Binance_wallet_spot = useSelector((state) => { return state.Binance_wallet_spot });
    let API_Address = useSelector((state) => { return state.API_Address.value })

    const darkname = dark ? 'dark' : "";

    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [Bbalance, setBBalance] = useState([]);
    const [spotBalance, setSpotBalance] = useState([])
    const [BcurrentUSDT, setBCurrentUSDT] = useState("")
    const [walletData, setWalletData] = useState([])
    const [reload, setReload] = useState(false)
    const [fastCoin, setFastCoin] = useState("")
    const [check_bnKyc, setCheck_bnKyc] = useState(false)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            B_SpotBalance(token);
        }
    }, [reload, Binance_wallet_spot])

    useEffect(() => {
        if (Binance_wallet_futures.length > 0) {
            setBCurrentUSDT(Binance_wallet_futures[0])
        }
    }, [Binance_wallet_futures])

    useEffect(() => {
        let data = [];
        if (BinanceS && Bbalance) {
            for (let i = 0; i < BinanceS.length; i++) {
                let num = 0;
                for (let j = 0; j < Bbalance.length; j++) {
                    if (BinanceS[i].ccy == Bbalance[j].asset) {
                        num = 1
                        data.push({ symbol: BinanceS[i].ccy, name: BinanceS[i].name, fullName: BinanceS[i].fullName, lastPrice: BinanceS[i].lastPrice, volume: BinanceS[i].volume, availBal: Bbalance[j].free, frozenBal: Bbalance[j].locked })
                    }
                }
                if (num == 0) data.push({ symbol: BinanceS[i].ccy, name: BinanceS[i].name, fullName: BinanceS[i].fullName, lastPrice: BinanceS[i].lastPrice, volume: BinanceS[i].volume, availBal: 0, frozenBal: 0 })
            }

            for (let j = 0; j < Bbalance.length; j++) {
                if ("USDT" == Bbalance[j].asset) {
                    data.push({ symbol: "USDT", name: "USDT", fullName: "USDT", lastPrice: 1, volume: Number(Bbalance[j].free) + Number(Bbalance[j].locked), availBal: Bbalance[j].free, frozenBal: Bbalance[j].locked })
                }
            }

            const isSymbolIncluded = data.some(item => item.symbol === "USDT");
            if (!isSymbolIncluded) {
                data.push({ symbol: "USDT", name: "USDT", fullName: "USDT", lastPrice: 1, volume: 0, availBal: 0, frozenBal: 0 })
            }

            data.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice))
            setWalletData(data)
        }
    }, [Bbalance, BinanceS])

    const B_SpotBalance = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/bn/spot/info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            let cnt = true
            if (tmp) {
                if (tmp.length > 0) {
                    for (let i = 0; i < tmp.length; i++) {
                        if (tmp[i].asset == "USDT") {
                            setSpotBalance(tmp[i])
                            cnt = false
                        }
                    }
                    if (cnt) {
                        setSpotBalance({ asset: "USDT", free: "0", freeze: "0" })
                    }
                }
                else {
                    setSpotBalance({ asset: "USDT", free: "0", freeze: "0" })
                }
                setBBalance(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 63, "msg": error }))
        })
    }

    useEffect(() => {
        if (location.state === null) setTab(0)
        else {
            if (location.state.num === 5 && check_KYC && check_KYC.bnKyc === 0 || location.state.num === 5 && check_KYC && check_KYC.etcKyc === 0) {
                setCheck_bnKyc(true)
                setTab(location.state.num)
            }
            else {
                setCheck_bnKyc(false)
                setTab(location.state.num)
            }
        }
    }, [location, check_KYC])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1080) {
                setSlidesPerView(1);
            }
            else {
                setSlidesPerView(0);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 화면 크기에 따른 슬라이드 수 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        AOS.init();
    }, [])

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "RF-Rev│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, []);

    return (
        <div className={`wallet ${darkname}`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            <div className='history-container' style={{ filter: check_KYC && check_KYC.bnKyc === 0 && Wallet_Page === 1 && tab != 0 || check_bnKyc ? "blur(5px)" : "" }}>
                <div className='history-wrap'>
                    <div className='history-left'>
                        {
                            slidesPerView === 0
                                ?
                                <ul>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 0 }} >
                                            <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(0); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/wallet_overview.png' style={{ width: "14px" }} />
                                                    {t('header_29')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 1 }} >
                                            <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(1); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/deposit.png' style={{ width: "14px" }} />
                                                    {t('header_31')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 2 }} >
                                            <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(2); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/withdraw.png' style={{ width: "14px" }} />
                                                    {t('header_33')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 3 }} >
                                            <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(3); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/convert.png' style={{ width: "14px" }} />
                                                    {t('header_35')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 4 }} >
                                            <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(4); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/transfer.png' style={{ width: "14px" }} />
                                                    {t('header_37')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 5 }} >
                                            <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(5); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/exchange.png' style={{ width: "14px" }} />
                                                    {t('header_41')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/wallet`} state={{ num: 6 }} >
                                            <div className={tab === 6 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(6); dispatch(changeWalletPage(-1)) }}>
                                                <div className='history-tit-box'>
                                                    <img src='/img/icon/gift.png' style={{ width: "14px" }} />
                                                    {t('header_39')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                :
                                <div style={{ position: "relative" }}>
                                    <div className={`box-pre ${darkname}`}></div>
                                    <div className={`box-next ${darkname}`}></div>
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={10}
                                        initialSlide={tab}
                                        freeMode={true}
                                        centeredSlides={true}
                                        slideToClickedSlide={true}
                                        centeredSlidesBounds={true}
                                        modules={[FreeMode, Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 0 }} >
                                                <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(0); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/wallet_overview.png' style={{ width: "14px" }} />
                                                        {t('header_29')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 1 }} >
                                                <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(1); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/deposit.png' style={{ width: "14px" }} />
                                                        {t('header_31')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 2 }} >
                                                <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(2); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/withdraw.png' style={{ width: "14px" }} />
                                                        {t('header_33')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 3 }} >
                                                <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(3); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/convert.png' style={{ width: "14px" }} />
                                                        {t('header_35')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 4 }} >
                                                <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(4); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/transfer.png' style={{ width: "14px" }} />
                                                        {t('header_37')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-big'>
                                            <Link to={`/wallet`} state={{ num: 5 }} >
                                                <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(5); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/exchange.png' style={{ width: "14px" }} />
                                                        {t('header_41')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide className='wallet-small'>
                                            <Link to={`/wallet`} state={{ num: 6 }} >
                                                <div className={tab === 6 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => { setTab(6); dispatch(changeWalletPage(-1)) }}>
                                                    <div className='history-tit-box'>
                                                        <img src='/img/icon/gift.png' style={{ width: "14px" }} />
                                                        {t('header_39')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                        }
                    </div>
                    {
                        tab === 0
                            ?
                            <Binance_Overview darkname={darkname} walletData={walletData} BcurrentUSDT={BcurrentUSDT} setFastCoin={setFastCoin} />
                            :
                            tab === 1
                                ?
                                Wallet_Page === -1
                                    ?
                                    <Common_Deposit darkname={darkname} />
                                    :
                                    <Binance_Deposit darkname={darkname} walletData={walletData} fastCoin={fastCoin} />
                                :
                                tab === 2
                                    ?
                                    Wallet_Page === -1
                                        ?
                                        <Common_Withdrawal darkname={darkname} />
                                        :
                                        <Binance_Withdrawal darkname={darkname} walletData={walletData} setReload={setReload} fastCoin={fastCoin} />
                                    :
                                    tab === 3
                                        ?
                                        Wallet_Page === -1
                                            ?
                                            <Common_Exchange darkname={darkname} />
                                            :
                                            <Binance_Exchange darkname={darkname} walletData={walletData} spotBalance={spotBalance} setReload={setReload} fastCoin={fastCoin} />
                                        :
                                        tab === 4
                                            ?
                                            Wallet_Page === -1
                                                ?
                                                <Common_Transfer darkname={darkname} />
                                                :
                                                <Binance_Transfer darkname={darkname} spotBalance={spotBalance} BcurrentUSDT={BcurrentUSDT} setReload={setReload} />
                                            :
                                            tab === 5
                                                ?
                                                check_bnKyc
                                                    ?
                                                    <Binance_Overview darkname={darkname} walletData={walletData} BcurrentUSDT={BcurrentUSDT} setFastCoin={setFastCoin} />
                                                    :
                                                    <Binance_QuickWithdrawal darkname={darkname} spotBalance={spotBalance} reload={reload} setReload={setReload} />
                                                :
                                                Wallet_Page === -1
                                                    ?
                                                    <Common_Gift darkname={darkname} />
                                                    :
                                                    <Binance_Gift darkname={darkname} BcurrentUSDT={BcurrentUSDT} setReload={setReload} />
                    }
                </div>
            </div>
            {
                check_KYC && check_KYC.bnKyc === 0 && Wallet_Page === 1 && tab != 0 || check_bnKyc
                    ?
                    <div className='spot-kyc-modal'>
                        <div className={`spot-kyc-modal-1 ${darkname}`}>
                            <div className='spot-kyc-modal-text'>{t('message_4')}<br />{t('message_5')}</div>
                            <Link to={`/dashboard`} state={{ num: 1 }} onClick={() => { setCheck_bnKyc(false); dispatch(changeWalletPage(-1)) }}>
                                <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    )
}

export default BWallet;