import "../../css/wallet.css";
import axios from "axios";
import Page from "../../Page";
import Country from "../../country";
import base64 from "base-64";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft, } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectType, checkLogin, addImg, changeWalletPage, check_Error, } from "../../../store";
import IMG from "../../img";
import { SyncLoader } from "react-spinners";
import Message from "../../message/message";
import Wallet_Message from "../../message/wallet_message";
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp, } from "../../common/function";

let fee = "";

function OKX_Withdrawal({ darkname, walletData, reload, setReload, fastCoin }) {
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const { t } = useTranslation();

    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [search, setSearch] = useState(fastCoin);
    const [selectSearch, setSelectSearch] = useState(fastCoin);
    const [selectCoin, SetSelectCoin] = useState([]);
    const [checkOKXLine, setCheckOKXLine] = useState("3");
    const [selectedOption, setSelectedOption] = useState("");
    const [checkRF, setCheckRF] = useState("");
    const [selectedAddress, setSelectedAddress] = useState(t("wallet_238"));
    const [modalbtn, setModalbtn] = useState(false);
    const [modalbtn1, setModalbtn1] = useState(false);
    const [currency, setCurrency] = useState([]);
    const [whistory, setWhistory] = useState([]);
    const [address, setAddress] = useState("");
    const [tag, setTag] = useState("");
    const [amount, setAmount] = useState("");
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [tab, setTab] = useState(false);
    const [exchangeID, setExchangeID] = useState([]);
    const [searchexchangeID, setSearchExchangeID] = useState("");
    const [selectexchangeID, setSelectExchangeID] = useState([]);
    const [resetHistory, setResetHistory] = useState(false);
    const [coinTotalUSDT, setCoinTotalUSDT] = useState("0");

    const [spinner, setSpinner] = useState(false);
    const [message, setMessage] = useState([]);
    const [phone, setPhone] = useState("");
    const [checkemail, setCheckEmail] = useState(false);
    const [okxuid, setOkxuid] = useState("");

    const [code, setCode] = useState(["", "", "", "", "", ""]); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector("input");
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector("input");
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    };

    const CheckkOKXLine = (event) => {
        setCheckOKXLine(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleCheckRF = (event) => {
        setAddress("");
        setCheckRF(event.target.value);
    };

    const handleSelectAddress = (event) => {
        setAddress("");
        setSelectedAddress(event.target.value);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = JSON.parse(base64.decode(payload));

            setOkxuid(dec.okxUid);
        }
    }, []);

    const emailRegEx =
        /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;

    const onChangeAddress = (e) => {
        const { value } = e.target;
        let onlyNumber;
        if (checkOKXLine === "3") {
            if (selectedAddress === "Phone Number") {
                onlyNumber = value.replace(/[^0-9]/g, "");
                if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == "0") {
                    onlyNumber = onlyNumber.substring(1);
                }
            }
            else if (selectedAddress === "Email") onlyNumber = value.replace(/[^\\!-z]/gi, "");
            else onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "");
        }
        else {
            onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "");
        }

        if (emailRegEx.test(onlyNumber)) setCheckEmail(true);
        else setCheckEmail(false);

        setAddress(onlyNumber);
    };

    const onChangeTag = (e) => {
        const { value } = e.target;
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "");
        setTag(onlyNumber);
    };

    const onChangeAmount = (e) => {
        const { value } = e.target;
        const onlyNumber = value.replace(/[^0-9.]/g, "");
        setAmount(onlyNumber);
    };

    const onSearchExchangeID = (e) => {
        setSearchExchangeID(e.target.value);
    };

    let BS_filterSymbol = [];
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        });
    }

    let filterExchange = [];
    if (exchangeID) {
        filterExchange = exchangeID.filter((p) => {
            return p.exchName.toLocaleLowerCase().includes(searchexchangeID.toLocaleLowerCase());
        });
    }

    useEffect(() => {
        if (walletData) {
            for (let i = 0; i < walletData.length; i++) {
                if (walletData[i].symbol === selectSearch) {
                    setBalanceAmount(Number(walletData[i].availBal));
                }
            }
        }
    }, [selectSearch]);

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositLink(token);
            DepositCurrency(token);
            ExchID(token);
        }
    }, [selectSearch, reload]);

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            WithdrawalHistory(token);
        }
    }, [reload, resetHistory]);

    const DepositLink = async (token) => {
        if (selectSearch && selectSearch.length > 0) {
            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-address`, {
                params: {
                    ccy: selectSearch,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let tmp = response.data.data;
                if (tmp) {
                    SetSelectCoin(tmp);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 146, "msg": error }));
            });
        }
    };

    const DepositCurrency = async (token) => {
        if (selectSearch && selectSearch.length > 0) {
            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/currencies`, {
                params: {
                    ccy: selectSearch,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let tmp = response.data.data;
                if (tmp) {
                    setCurrency(tmp);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 147, "msg": error }));
            });
        }
    };

    const ExchID = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/exch-id`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data.data;
            if (tmp) {
                setExchangeID(tmp);
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 148, "msg": error }));
        });
    };

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem("token");
        if (objString != null) {
            let obj = JSON.parse(objString);
            let jwt = obj.value;

            setSpinner(true);

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                params: { otp: code.join("") },
                headers: { Authorization: `Bearer ${jwt}` },
            }).then((response) => {
                if (response.data.code > 0) {
                    WithdrawalLink();
                } else {
                    alert(response.data.msg);
                    setW_confirm(false);
                    setSpinner(false);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 149, "msg": error }));
            });
        }
    };

    const WithdrawalLink = async () => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/wallet/okx/withdrawal`, {
                ccy: selectSearch,
                amt: amount,
                dest: Number(checkOKXLine),
                address: address,
                chain: selectedOption,
                tag: tag,
                fee: checkOKXLine === "3" ? 0 : fee,
                exchId: selectexchangeID,
                areaCode: phone,
                rf: checkRF == "0" ? true : false,
                travel:
                    Number(coinTotalUSDT) * Number(amount) >= 730 ? true : false,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (response.data.code >= 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload((e) => !e);
                        setAddress("");
                        setSearch("");
                        setTag("");
                        setAmount("");
                        setSearchExchangeID("");
                        setCode(["", "", "", "", "", ""]);

                        setMessage({
                            type: 0,
                            code: Number(response.data.code),
                            msg: `${t("message_15")}`,
                        });
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({
                            type: 0,
                            code: Number(response.data.code),
                            msg: response.data.msg,
                        });
                    }, 1000);
                }
                setW_confirm(false);
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 150, "msg": error }));
            });
        }
    };

    const WithdrawalHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/withdrawal-history`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data.data;
            if (tmp) {
                setWhistory(tmp);
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 151, "msg": error }));
        });
    };

    const [w_message, setW_message] = useState([]);
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false);

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false);
            CheckGoogleOTP();
        }
    }, [w_confirm]);

    const Check_Modal = () => {
        let mes = [];
        // mes.push(`Exchange: OKX`)
        // mes.push(`Withdrawal Method: ${checkOKXLine === '3' ? "Internal Transfer" : "On-Chain Withdrawal"}`)
        // mes.push(`Coin: ${selectSearch}`)
        // mes.push(`Network: ${selectedOption}`)
        // if (checkOKXLine === '3') {
        //     mes.push(`RF-user: ${checkRF == "0" ? "RF-user" : "Not an RF user"}`)
        //     mes.push(`Address Type: ${selectedAddress}`)
        // }
        // mes.push(`Address: ${address}`)
        // if (checkOKXLine === '4') mes.push(`Memo/Tag: ${tag}`)
        // mes.push(`Amount: ${amount}`)
        // if (Number(coinTotalUSDT) * Number(amount) >= 730) mes.push(`Destination: ${searchexchangeID}`)

        if (selectedAddress == "Email" && checkOKXLine === "3" && !checkemail) {
            alert(`${t("message_25")}`);
        }
        else {
            setW_message({ code: 0, msg: mes });
        }
    };

    const [page, setPage] = useState(1);
    let filter_whistory = whistory.slice((page - 1) * 10, page * 10);

    return (
        <div className="history-right">
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className="dashboard-right-bottom1-modal">
                        <SyncLoader color={`${API_Address == "rf" ? "#5381e3" : "#F5AC1D"}`} />
                    </div>
                    :
                    ""
            }
            <div className="history-right-top">
                <div className="history-right-top-1">{t("header_33")}</div>
                <p className="history-right-top-2">{t("header_34")}</p>
            </div>
            <div className="wallet-right-form">
                <div className="wallet-right-form-1">
                    <div className="wallet-right-form-1-box">
                        <div className="wallet-right-form-1-box-1">
                            <p>{t("wallet_10")}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? "toggled" : ""}  ${darkname}`} onClick={toggleButton1} >
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className="wallet-right-form-3">
                            <div className="wallet-right-form-3-1">{t("wallet_10")}</div>
                            <div className="wallet-right-form-3-2">
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_84")}</p>
                                    <p className="wallet-right-form-3-2-3">
                                        1. {t("wallet_85")} <br />
                                        2. {t("wallet_86")} <br />
                                        3. {t("wallet_87")} <br />
                                        4. {t("wallet_88")}
                                    </p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_89")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_90")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_91")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_92")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_93")}</p>
                                    <p className="wallet-right-form-3-2-3">
                                        {t("wallet_94")} <br />
                                        1. {t("wallet_95")} <br />
                                        2. {t("wallet_96")} <br /> <br />
                                        {t("wallet_34")} <br />- {t("wallet_97")} <br />-{" "}
                                        {t("wallet_98")} <br />- {t("wallet_99")}
                                    </p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_100")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_101")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_102")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab((e) => !e)}>{t("exchange_1")}</div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false); }}>{t("exchange_0")}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false); }}>{t("exchange_1")}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_233")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select value={checkOKXLine} onChange={CheckkOKXLine}>
                                <option value="4">{t("wallet_235")}</option>
                                <option value="3">{t("wallet_234")}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_53")}</div>
                    <div className="wallet-right-form-4-2" onClick={() => { setModalbtn(true); setSearch(""); }}>
                        <input type="text" className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t("wallet_54")}`} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className="wallet-right-bottom1-modal">
                                <div className="wallet-right-bottom1-modal-1">
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className="wallet-right-bottom1-modal-1-1-tit">{t("wallet_55")} <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} />
                                        </div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t("wallet_75")}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className="wallet-right-bottom1-modal-1-2">
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true;
                                                        if (IMG && !IMG.includes(a.symbol)) {
                                                            imgcheck = false;
                                                        }
                                                        return (
                                                            <div className="wallet-right-bottom1-modal-1-2-1" onClick={() => { setSelectSearch(a.symbol); setSearch(a.symbol); setCoinTotalUSDT(a.lastPrice); setModalbtn(false); }}>
                                                                <div className="wallet-right-bottom1-modal-1-2-2">
                                                                    <div className="wallet-right-bottom1-modal-1-2-img">
                                                                        <img src={imgcheck ? `/img/coin/${a.symbol}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className="wallet-right-bottom1-modal-1-2-name">
                                                                        <p>{a.symbol}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {/* <br />
                    <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                    <div className='wallet-right-form-4-4'>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("USDT"); setSelectSearch("USDT") }}>USDT</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                        </div>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                        </div>
                    </div> */}
                </div>

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_57")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">{t("wallet_58")}</option>
                                {selectCoin.map((option, index) => (
                                    <option key={index} value={option.chain}>
                                        {option.chain}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <p className="wallet-right-form-5">
                    {t("wallet_103")}
                    <br />
                    {t("wallet_104")}
                </p>

                {
                    checkOKXLine === "3"
                        ?
                        <>
                            <div className={`wallet-right-form-4 ${darkname}`}>
                                <div className="wallet-right-form-4-1">{t("wallet_149")}</div>
                                <div className="wallet-right-form-4-2">
                                    <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                                        <select value={checkRF} onChange={handleCheckRF}>
                                            <option value="">{t("wallet_58")}</option>
                                            <option value="0">{t("wallet_226")}</option>
                                            <option value="1">{t("wallet_227")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={`wallet-right-form-4 ${darkname}`}>
                                <div className="wallet-right-form-4-1">{t("wallet_228")}</div>
                                <div className="wallet-right-form-4-2">
                                    <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                                        <select
                                            value={selectedAddress}
                                            onChange={handleSelectAddress}
                                        >
                                            <option value="Address">{t("wallet_58")}</option>
                                            {
                                                checkRF && checkRF.length > 0
                                                    ?
                                                    <>
                                                        {
                                                            checkRF === "0"
                                                                ?
                                                                <option value={`${t("wallet_232")}`}>{t("wallet_232")}</option>
                                                                :
                                                                <>
                                                                    <option value={`${t("wallet_229")}`}>{t("wallet_229")}</option>
                                                                    <option value={`${t("wallet_230")}`}>{t("wallet_230")}</option>
                                                                    <option value={`${t("wallet_231")}`}>{t("wallet_231")}</option>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">
                        {checkOKXLine === "3" ? `${t("wallet_237")} ${selectedAddress}` : t("wallet_105")}
                    </div>
                    <div className="wallet-right-form-4-2">
                        {
                            selectedAddress == `${t("wallet_229")}` && checkOKXLine === "3"
                                ?
                                <div className={`withdraw-country  ${darkname}`}>
                                    <input type="search" className="regist-box-left-form-country-input" placeholder="Select" list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                    <datalist id="list">
                                        {
                                            Country.map(function (a, i) {
                                                return (
                                                    <option value={a.value}>
                                                        {a.name} ({a.value})
                                                    </option>
                                                );
                                            })
                                        }
                                    </datalist>
                                </div>
                                :
                                ""
                        }
                        <input className={`wallet-right-form-4-3 ${darkname}`} value={address} onChange={onChangeAddress} placeholder="-" style={{ paddingLeft: selectedAddress == t("wallet_229") && checkOKXLine === "3" ? "100px" : "", }} />
                    </div>
                </div>
                {
                    checkOKXLine === "4"
                        ?
                        <div className={`wallet-right-form-4 ${darkname}`}>
                            <div className="wallet-right-form-4-1">{t("wallet_63")}</div>
                            <div className="wallet-right-form-4-2">
                                <input className={`wallet-right-form-4-3 ${darkname}`} value={tag} onChange={onChangeTag} placeholder="-" />
                            </div>
                        </div>
                        :
                        ""
                }

                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className="wallet-right-form-4-1">{t("wallet_106")}</div>
                    <div className="wallet-right-form-4-1-1">
                        {changeNum(RoundDown(balanceAmount, 5))}
                        <span className="wallet-right-form-4-1-1-span">{selectSearch}</span>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_107")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input className="wallet-right-form-4-3-input" value={amount} onChange={onChangeAmount} placeholder="0" />
                            <span className="wallet-right-form-4-3-span">{selectSearch}</span>
                            <div className="wallet-right-form-4-3-btn" onClick={() => setAmount(balanceAmount)}>{t("wallet_108")}</div>
                        </div>
                    </div>
                </div>
                {currency.map(function (a, i) {
                    if (a.chain == selectedOption) {
                        return (
                            <div className={`wallet-right-form-4 ${darkname}`}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="wallet-right-form-4-1" style={{ fontWeight: "500", opacity: ".5" }}>{t("wallet_109")}</div>
                                    <div className="wallet-right-form-4-1-1">{a.minWd}</div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="wallet-right-form-4-1" style={{ fontWeight: "500", opacity: ".5" }}>{t("wallet_110")}</div>
                                    <div className="wallet-right-form-4-1-1" onChange={(fee = checkOKXLine === "3" ? 0 : a.minFee)}>{checkOKXLine === "3" ? 0 : a.minFee}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
                {
                    checkOKXLine == "4" && Number(coinTotalUSDT) * Number(amount) >= 730
                        ?
                        <div className={`wallet-right-form-4 ${darkname}`}>
                            <div className="wallet-right-form-4-1">{t("wallet_217")}</div>
                            <div className="wallet-right-form-4-2" onClick={() => { setModalbtn1(true); setSearchExchangeID(""); }}>
                                <input type="text" className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t("wallet_218")}`} value={searchexchangeID} onChange={onSearchExchangeID} />
                            </div>
                            {
                                modalbtn1
                                    ?
                                    <div className="wallet-right-bottom1-modal">
                                        <div className="wallet-right-bottom1-modal-1">
                                            <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                                <div className="wallet-right-bottom1-modal-1-1-tit">
                                                    {t("wallet_219")}
                                                    <FontAwesomeIcon icon={faXmark} onClick={() => setModalbtn1(false)} />
                                                </div>
                                                <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                                    <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                        <input placeholder={`${t("wallet_75")}`} onChange={onSearchExchangeID} value={searchexchangeID} />
                                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                    </div>
                                                    <div className="wallet-right-bottom1-modal-1-2">
                                                        {
                                                            filterExchange.map(function (a, i) {
                                                                return (
                                                                    <div
                                                                        className="wallet-right-bottom1-modal-1-2-1"
                                                                        onClick={() => { setSelectExchangeID(a); setSearchExchangeID(a.exchName); setModalbtn1(false); }}>
                                                                        <div className="wallet-right-bottom1-modal-1-2-2">
                                                                            <div className="wallet-right-bottom1-modal-1-2-name">
                                                                                <p>{a.exchName}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        :
                        ""
                }

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_111")}</div>
                    <div className="wallet-right-form-4-2" style={{ opacity: ".5" }}>{t("wallet_112")}</div>
                    <div className="wallet-right-form-8">
                        {
                            code.map((digit, index) => (
                                <div className={`wallet-right-form-8-1 ${darkname}`}>
                                    <input key={index} type="text" maxLength="1" value={digit} onChange={(e) => handleInputChange(e, index)} className={`wallet-right-form-8-2 ${darkname}`} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="wallet-right-form-9">
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t("wallet_113")}</div>
                    {/* <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => alert("Coming Soon")}>{t('wallet_113')}</div> */}
                </div>
                <div className="wallet-right-form-7">
                    <div className="wallet-right-form-7-1">{t("wallet_114")}</div>
                    <div className="wallet-right-form-7-2"><FontAwesomeIcon icon={faRotate} onClick={() => setResetHistory((e) => !e)} /></div>
                    <div className="wallet-right-form-7-3">
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className="wallet-right-form-7-3-top-1">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_68")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_69")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-1">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_70")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_71")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-3">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_72")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_73")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-4 wallet-display-none-800">{t("wallet_74")}</div>
                        </div>
                        <div className="wallet-right-form-7-3-bottom">
                            {
                                whistory && whistory.length === 0
                                    ?
                                    <div className="history-right-bottom-4-bottom-1">
                                        <img src="/img/img_nofound.png"></img>
                                        <img src="/img/img_nofound_dark.svg" style={{ display: "none" }}></img>
                                        <p>{t("message_0")}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_whistory.map(function (a, i) {
                                                return <WithdrawItem key={i} a={a} darkname={darkname} />;
                                            })
                                        }
                                        <Page initialData={whistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function WithdrawItem({ a, darkname }) {
    const { t } = useTranslation();
    const [state, setState] = useState("");
    const date = UTCTimestamp(a.ts);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    function padZero(value) {
        return value < 10 ? `0${value}` : value;
    }

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);
    const [mouseState2, setMouseState2] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };
    const onMouseEnter2 = () => {
        setMouseState2(true);
    };
    const onMouseLeave2 = () => {
        setMouseState2(false);
    };

    useEffect(() => {
        let tmp = a.state;
        if (tmp) {
            if (Number(tmp) === 0) setState("waiting for confirmation");
            else if (Number(tmp) === 1) setState("deposit credited");
            else if (Number(tmp) === 2) setState("Completed");
            else if (Number(tmp) === 8) setState("pending due to temporary deposit suspension on this crypto currency");
            else if (Number(tmp) === 11) setState("match the address blacklist");
            else if (Number(tmp) === 12) setState("account or deposit is frozen");
            else if (Number(tmp) === 13) setState("sub-account deposit interception");
            else if (Number(tmp) === 14) setState("KYC limit");
        }
    }, [a]);

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className="wallet-right-form-7-3-bottom-2">
                <div className="wallet-right-form-7-3-bottom-3">{formattedDate}</div>
                <div className="wallet-right-form-7-3-bottom-3">{t("exchange_1")}</div>
            </div>
            <div className="wallet-right-form-7-3-bottom-2">
                <div className="wallet-right-form-7-3-bottom-3" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txId ? a.txId : "Internal transfer"}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txId ? a.txId : "Internal transfer"}</div>
                            :
                            ""
                    }
                </div>
                <div className="wallet-right-form-7-3-bottom-3">{a.amt}</div>
            </div>
            <div className="wallet-right-form-7-3-bottom-4">
                <div className="wallet-right-form-7-3-bottom-3">{a.ccy}</div>
                <div className="wallet-right-form-7-3-bottom-3" onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1} style={{ cursor: "pointer" }}>
                    {a.chain ? a.chain : "Internal"}
                    {
                        mouseState1
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.chain ? a.chain : "Internal"}</div>
                            :
                            ""
                    }
                </div>
            </div>
            <div className="wallet-right-form-7-3-top-5 wallet-display-none-800" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2} style={{ cursor: "pointer" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }} >{state}</div>
                {
                    mouseState2
                        ?
                        <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{state}</div>
                        :
                        ""
                }
            </div>
        </div>
    );
}

export default OKX_Withdrawal;
