import '../../css/futures.css'
import axios from 'axios';
import base64 from 'base-64';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faSquareCaretDown, faCircleXmark, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { useTranslation } from "react-i18next";
import { checkAlert, check_Error } from '../../../store';
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"
import { ClipLoader } from "react-spinners";

let openorder_data = []
let cnt_login = 0

function Fsection8({ tab5, setTab5, symbol, windowtypeM, USDTSymbolF, setPositionAmount, positionData, setTradeVolume, tradeVolume, setCheckisPlaying, checkCurrentPosition, currentPrice }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Alert = useSelector((state) => { return state.Alert });
    let login = useSelector((state) => { return state.login.value });

    const CryptoJS = require('crypto-js');
    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();

    const [positionbtn, setPositionbtn] = useState(true)
    const [openorder, setOpenorder] = useState([])
    const [message, setMessage] = useState([])

    const [isPlaying, setIsPlaying] = useState(false);

    const [delay, setDelay] = useState(true)
    const [openorder_cancel, setOpenorder_cancel] = useState(true)
    // const [timestamp, setTimestamp] = useState("")
    // const [timestamp1, setTimestamp1] = useState("")

    let timestamp = String(Date.now() / 1000)
    let timestamp1 = String(Date.now() / 1000)

    const playAudio = () => {
        setIsPlaying(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    useEffect(() => {
        if (cnt_login > 0) {
            FuturesOpenOrder();
            // Timestamp()
            // Timestamp1()
        }
        cnt_login += 1
    }, [login])

    // const Timestamp = async () => {
    //     // let local_time = localStorage.getItem("okx_timestamp")
    //     // if (local_time && JSON.parse(local_time).delay >= Date.now()) {
    //     //     setTimestamp(JSON.parse(local_time).delay)
    //     //     setTimestamp1(JSON.parse(local_time).delay)
    //     // }
    //     // else {
    //     await axios.get("https://www.okx.com/api/v5/public/time", {
    //     }).then((response) => {
    //         let tmp = response.data.data
    //         if (tmp && tmp[0] && tmp[0].ts) {
    //             // localStorage.removeItem("okx_timestamp")
    //             setTimestamp(String(Number(tmp[0].ts) / 1000))
    //             // let obj = {
    //             //     time: String(Number(tmp[0].ts) / 1000),
    //             //     delay: Date.now() + 20000
    //             // }
    //             // const objString = JSON.stringify(obj);
    //             // localStorage.setItem("okx_timestamp", objString)
    //         }
    //     }).catch((error) => {
    //         dispatch(check_Error({ "bol": true, "no": 127, "msg": error }))
    //     })
    //     // }
    // }

    // const Timestamp1 = async () => {
    //     // let local_time = localStorage.getItem("okx_timestamp")
    //     // if (local_time && JSON.parse(local_time).delay >= Date.now()) {
    //     //     setTimestamp(JSON.parse(local_time).delay)
    //     //     setTimestamp1(JSON.parse(local_time).delay)
    //     // }
    //     // else {
    //     await axios.get("https://www.okx.com/api/v5/public/time", {
    //     }).then((response) => {
    //         let tmp = response.data.data
    //         if (tmp && tmp[0] && tmp[0].ts) {
    //             // localStorage.removeItem("okx_timestamp")
    //             setTimestamp1(String(Number(tmp[0].ts) / 1000))
    //             // let obj = {
    //             //     time: String(Number(tmp[0].ts) / 1000),
    //             //     delay: Date.now() + 20000
    //             // }
    //             // const objString = JSON.stringify(obj);
    //             // localStorage.setItem("okx_timestamp", objString)
    //         }
    //     }).catch((error) => {
    //         dispatch(check_Error({ "bol": true, "no": 128, "msg": error }))
    //     })
    //     // }
    // }

    const FuturesOpenOrder = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/open-orders", {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    let arr = []
                    for (let i = 0; i < tmp.length; i++) {
                        let data = tmp[i]
                        if (data.ordType == "trigger") arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side })
                        else if (data.ordType == "conditional") arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side })
                        else arr.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: 0, sz: data.sz, side: data.side })
                    }
                    setOpenorder(arr)
                    openorder_data = arr
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 129, "msg": error }))
            })
        }
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null && timestamp.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "orders",
                        instType: "SWAP",
                    }
                ]
            }

            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/private?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/private`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }
            socket.onmessage = function (event) {
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);
                    if (tmp && tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.data && tmp.data[0]) {
                        let arr = openorder_data;
                        let data = tmp.data[0]

                        if (data.state == "live") {
                            let fil = arr.filter((item) => item.ordId != data.ordId)
                            fil.push({ ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: data.fillSz, sz: data.sz, side: data.side })
                            if (Alert[2] == "ON") {
                                playAudio()
                            }
                            setOpenorder(fil)
                            openorder_data = fil
                        }
                        else if (data.state == "partially_filled") {
                            let arr1 = []
                            let fil = arr.filter((item) => item.ordId != data.ordId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: data.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                            })
                            arr1.push({ ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.px, fillSz: data.fillSz, sz: data.sz, side: data.side })

                            setOpenorder(arr1)
                            openorder_data = arr1
                        }
                        else {
                            let arr1 = []
                            let fil = arr.filter((item) => item.ordId != data.ordId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: data.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                            })
                            setOpenorder(arr1)
                            openorder_data = arr1
                        }

                    }
                }
            }
            socket.onclose = function (event) {
                // console.log("okx futures_openorder Close")
                // Timestamp()
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null && timestamp.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);
            const loginMessage = {
                op: "login",
                args: [
                    {
                        apiKey: JSON.parse(dec).apiKey,
                        passphrase: JSON.parse(dec).passphrase,
                        timestamp: timestamp,
                        sign: CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp + 'GET' + '/users/self/verify', JSON.parse(dec).secretKey))
                    }
                ]
            }
            const subscribeMessage = {
                op: "subscribe",
                args: [
                    {
                        channel: "orders-algo",
                        instType: "SWAP",
                    }
                ]
            }

            let socket = JSON.parse(dec).userId === 1 ? new WebSocket(`wss://wspap.okx.com:8443/ws/v5/business?brokerId=9999`) : new WebSocket(`wss://ws.okx.com:8443/ws/v5/business`);
            socket.onopen = function () {
                socket.send(JSON.stringify(loginMessage))
                const intervalId = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send("ping");
                    }
                }, 25000)

                return () => {
                    clearInterval(intervalId)
                }
            }
            socket.onmessage = function (event) {
                if (event.data != "pong") {
                    let tmp = JSON.parse(event.data);

                    if (tmp.event === "login") {
                        socket.send(JSON.stringify(subscribeMessage))
                    }
                    if (tmp && tmp.data && tmp.data[0]) {
                        let arr = openorder_data
                        let data = tmp.data[0]

                        if (data.state == "live") {
                            let fil = arr.filter((item) => item.algoId != data.algoId)
                            if (data.ordType == "conditional") fil.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side })
                            else fil.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side })

                            if (Alert[2] == "ON") {
                                playAudio()
                            }
                            setOpenorder(fil)
                            openorder_data = fil
                        }
                        else if (data.state == "partially_filled") {
                            let arr1 = []
                            let fil = arr.filter((item) => item.algoId != data.algoId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                            })

                            if (data.ordType == "conditional") arr1.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.slTriggerPx.length > 0 ? data.slTriggerPx : data.tpTriggerPx, fillSz: 0, sz: data.sz, side: data.side })
                            else arr1.push({ algoId: data.algoId, ordId: data.ordId, instId: data.instId, ordType: data.ordType, px: data.triggerPx, fillSz: 0, sz: data.sz, side: data.side })

                            setOpenorder(arr1)
                            openorder_data = arr1
                        }
                        else {
                            let arr1 = []
                            let fil = arr.filter((item) => item.algoId != data.algoId)

                            fil.map(function (a, i) {
                                if (a.ordType == "conditional" || a.ordType == "trigger") arr1.push({ algoId: a.algoId, ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                                else arr1.push({ ordId: a.ordId, instId: a.instId, ordType: a.ordType, px: a.px, fillSz: a.fillSz, sz: a.sz, side: a.side })
                            })
                            setOpenorder(arr1)
                            openorder_data = arr1
                        }

                    }
                }
            }
            socket.onclose = function (event) {
                // console.log("okx futures_algo_openorder Close")
                // Timestamp1()
            };
            socket.error = function (error) {
                window.location.reload()
            }
            return () => {
                socket.close();
            }
        }
    }, [login, timestamp1])

    const CancelAll = () => {
        let list = []
        let algo = []
        if (openorder) {
            for (let i = 0; i < openorder.length; i++) {
                let s = openorder[i]
                if (s.ordType == "conditional" || s.ordType == "trigger") {
                    if (s.instId == symbol) algo.push({ algoId: s.algoId, instId: s.instId })
                }
                else {
                    if (s.instId == symbol) list.push({ ordId: s.ordId, instId: s.instId })
                }
            }
        }

        if (list && list.length > 0) {
            FuturesAllOpenOrderCancel(list)
        }
        if (algo && algo.length > 0) {
            FuturesAllOpenOrderCancel_Algo(algo)
        }
    }

    const FuturesAllOpenOrderCancel = async (list) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel", {
                ordArr: list
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response.data.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response.data.code), msg: `${t('message_13')}` })
                }
                else {
                    setMessage({ type: "binance", code: Number(response.data.code), msg: response.data.msg })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 130, "msg": error }))
            })
        }
    }

    const FuturesAllOpenOrderCancel_Algo = async (algo) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setDelay(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/all-cancel/algo", {
                ordArr: algo
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (Number(response.data.code) >= 0) {
                    setMessage({ type: "binance", code: Number(response.data.code), msg: `${t('message_13')}` })
                    FuturesOpenOrder()
                }
                else {
                    setMessage({ type: "binance", code: Number(response.data.code), msg: response.data.msg })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 131, "msg": error }))
            })
        }
    }

    return (
        <div className='futures-section8'>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.6.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            <Modal message={message} />
            <div className={`futures-section8-top ${darkname}`}>
                <div className='futures-section8-top-1'>
                    <div className={tab5 === 0 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(0)}>{t('futures_79')}</div>
                    <div className={tab5 === 1 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(1)}>
                        <span>{t('futures_80')}</span> <span>({openorder ? openorder.filter(item => item.instId === symbol).length : "0"})</span></div>
                    <div className={tab5 === 2 ? 'futures-section8-top-1-1 active' : 'futures-section8-top-1-1'} onClick={() => setTab5(2)}>
                        <span>{t('futures_81')}</span> <span>({openorder ? openorder.length : "0"})</span>
                    </div>
                    <div className={tab5 === 3 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(3)}>{t('futures_82')}</div>
                    {/* <div className={tab5 === 4 ? 'futures-section8-top-1-1 display-none-1000 active' : 'futures-section8-top-1-1 display-none-1000'} onClick={() => setTab5(4)} style={{ color: "#F5AC1D" }}>{t('futures_83')}</div> */}
                    <div className='futures-section8-top-1-2 display-none-1000'>
                        <input type='checkbox' disabled={true} />
                    </div>
                </div>
                <div className='futures-section8-top-2'>
                    {
                        delay
                            ?
                            <div className='futures-section8-top-1-3' onClick={() => CancelAll()}>{t('spot_46')}</div>
                            :
                            <div className='futures-section8-top-1-3'><ClipLoader color="#595959" size='17px' /></div>
                    }
                </div>
                <FontAwesomeIcon icon={positionbtn ? faSquareCaretUp : faSquareCaretDown} className='display-none-700-block' style={{ color: "gray", fontSize: "20px", paddingRight: "5px" }} onClick={() => setPositionbtn(btn => !btn)} />
            </div>
            <div className='futures-section8-bottom'>
                {
                    tab5 === 0
                        ?
                        <Position positionbtn={positionbtn} darkname={darkname} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setMessage={setMessage} setPositionAmount={setPositionAmount} positionData={positionData} setCheckisPlaying={setCheckisPlaying} symbol={symbol} checkCurrentPosition={checkCurrentPosition} currentPrice={currentPrice} />
                        :
                        tab5 === 1
                            ?
                            <OpenOrder positionbtn={positionbtn} openorder={openorder} symbol={symbol} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                            :
                            tab5 === 2
                                ?
                                <DiffOrder openorder={openorder} symbol={symbol} darkname={darkname} setMessage={setMessage} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                :
                                tab5 === 3
                                    ?
                                    <Alarm setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} />
                                    :
                                    <Multi />
                }
            </div>
        </div>
    )
}

function Position({ positionbtn, darkname, windowtypeM, USDTSymbolF, setMessage, setPositionAmount, positionData, setCheckisPlaying, symbol, checkCurrentPosition, currentPrice }) {
    let Alert = useSelector((state) => { return state.Alert });
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const timestamp1 = '' + Date.now();

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);

    const playAudio = () => {
        setIsPlaying(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying1(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const FuturesPositionClose = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/close/position", {
                instId: p.instId,
                mgnMode: p.mgnMode,
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response.data.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: `${t('message_14')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: response.data.msg })
                    }
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 132, "msg": error }))
                })
        }
    }

    const createPostData = (p) => {
        return {
            instId: p.instId,
            tdMode: p.mgnMode,
            lever: p.lever,
            clOrdId: timestamp1,
            side: Number(p.pos) >= 0 ? "sell" : "buy",
            ordType: "market",
            px: "",
            sz: Math.floor(Math.abs(p.pos) / 2) > 0 ? Math.floor(Math.abs(p.pos) / 2) : Math.abs(p.pos),
            reduceOnly: true,
            tag: "ff5b6baef008SCDE",
            tgtCcy: Number(p.pos) >= 0 ? "base_ccy" : "quote_ccy",
        };
    };

    const onSubmitFutures = async (postData, url, p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (Number(response.data.code) === 0) {
                    if (p.upl >= 0 && Alert[1] == "ON") playAudio()
                    else if (p.upl < 0 && Alert[1] == "ON") playAudio1()

                    setMessage({ type: "okx", code: Number(response.data.code), msg: `${t('message_14')}` })
                }
                else {
                    setMessage({ type: "okx", code: Number(response.data.code), msg: response.data.data[0].sMsg })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 133, "msg": error }))
            })
        }
    }

    const FuturesPositionHalfClose = (p) => {
        let url = process.env.REACT_APP_API + '/api/v1/trade/okx/swap/order'
        let postData = createPostData(p);
        onSubmitFutures(postData, url, p);
    };

    return (
        <>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.3.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.4.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""

            }
            {
                windowtypeM
                    ?
                    <div className='position-box'>
                        <div className='position'>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_84')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_85')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_86')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_87')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_88')}</div>
                                </div>
                            </div>
                            <div className='position-1'>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_89')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_90')}</div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'><span>{t('futures_91')}</span><span>(USDT)</span></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_92')} <FontAwesomeIcon icon={faCrown} /></div>
                                </div>
                                <div className='position-2'>
                                    <div className='position-3'>{t('futures_93')}</div>
                                </div>
                            </div>
                        </div>
                        {
                            checkCurrentPosition
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.instId })
                                    if (a.instId == symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} FuturesPositionHalfClose={FuturesPositionHalfClose} myData={myData[0]} setPositionAmount={setPositionAmount} currentPrice={currentPrice} symbol={symbol} />
                                        )
                                    }
                                })
                                :
                                <PositionNoneItem darkname={darkname} />
                        }
                        {
                            positionData
                                ?
                                positionData.map(function (a, i) {
                                    let myData = USDTSymbolF.filter((item) => { return item.symbol === a.instId })
                                    if (a.instId != symbol) {
                                        return (
                                            <PositionWebItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} FuturesPositionHalfClose={FuturesPositionHalfClose} myData={myData[0]} setPositionAmount={setPositionAmount} currentPrice={currentPrice} symbol={symbol} />
                                        )
                                    }
                                })
                                :
                                ""
                        }
                    </div>
                    :
                    positionbtn
                        ?
                        <>
                            {
                                checkCurrentPosition
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.instId })
                                        if (a.instId == symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} FuturesPositionHalfClose={FuturesPositionHalfClose} myData={myData[0]} setPositionAmount={setPositionAmount} currentPrice={currentPrice} symbol={symbol} />
                                            )
                                        }
                                    })
                                    :
                                    <PositionNoneItem darkname={darkname} />
                            }
                            {
                                positionData
                                    ?
                                    positionData.map(function (a, i) {
                                        let myData = USDTSymbolF.filter((item) => { return item.symbol === a.instId })
                                        if (a.instId != symbol) {
                                            return (
                                                <PositionMobileItem key={i} positionData={a} darkname={darkname} FuturesPositionClose={FuturesPositionClose} FuturesPositionHalfClose={FuturesPositionHalfClose} myData={myData[0]} setPositionAmount={setPositionAmount} currentPrice={currentPrice} symbol={symbol} />
                                            )
                                        }
                                    })
                                    :
                                    ""
                            }
                        </>
                        :
                        ""
            }
        </>
    )
}

function PositionNoneItem({ darkname }) {
    const { t } = useTranslation();
    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}></div>
                </div>
                <div className='position-2'>
                </div>
            </div>
        </div>
    )
}

function PositionWebItem({ positionData, darkname, FuturesPositionClose, FuturesPositionHalfClose, myData, setPositionAmount, currentPrice, symbol }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((item) => item.instId === positionData.instId)
    const { t } = useTranslation();
    let arr = positionData.instId.split('-')

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <Link to={`/futures/okx/${positionData.instId ? positionData.instId : "BTC-USDT-SWAP"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.instId ? `${arr[0]}USDT` : ""}</div>
                    </Link>
                </div>
                <div className='position-2'>
                    {
                        positionData.pos
                            ?
                            Number(positionData.pos) >= 0
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.avgPx ? myData && myData.point ? `${changeNum(Number(positionData.avgPx).toFixed(myData.point))}` : `${changeNum(Number(positionData.avgPx))}` : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.pos && ctVal && ctVal[0] ? `${Math.abs(positionData.pos) * ctVal[0].ctVal}` : ""])} style={{ cursor: "pointer" }}>
                    <div className={`position-4 ${darkname}`}>{positionData.pos && ctVal && ctVal[0] ? `${changeNum(Math.abs(positionData.pos) * ctVal[0].ctVal)}` : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.markPx ? myData && myData.point ? `${changeNum(Number(positionData.markPx).toFixed(myData.point))}` : `${changeNum(Number(positionData.markPx))}` : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.mgnMode ? positionData.mgnMode == "cross" ? "Cross" : "Isolated" : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.instId ? positionData.liqPx === "" ? "-" : myData && myData.point ? `${Number(positionData.liqPx).toFixed(myData.point)}` : Number(positionData.liqPx) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className={`position-4 ${darkname}`}>{positionData.notionalUsd ? `${changeNum(Number(positionData.notionalUsd).toFixed(4, '0'))}` : ""}</div>
                </div>
                <div className='position-2'>
                    {
                        positionData.upl
                            ?
                            symbol === positionData.instId
                                ?
                                Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)) >= 0
                                    ?
                                    <div className={`position-4 ${darkname} green`}>{changeNum(Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)).toFixed(3))}</div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>{changeNum(Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)).toFixed(3))}</div>
                                :
                                Number(positionData.upl) >= 0
                                    ?
                                    <div className={`position-4 ${darkname} green`}>{changeNum(Number(positionData.uplLastPx).toFixed(3))}</div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>{changeNum(Number(positionData.uplLastPx).toFixed(3))}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionHalfClose(positionData)}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PositionMobileItem({ positionData, darkname, FuturesPositionClose, FuturesPositionHalfClose, myData, setPositionAmount, currentPrice, symbol }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((item) => item.instId === positionData.instId)
    const { t } = useTranslation();
    let arr = positionData.instId.split('-')

    return (
        <div className='position'>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_84')}</div>
                    <Link to={`/futures/okx/${positionData.instId ? positionData.instId : "BTC-USDT-SWAP"}`} style={{ cursor: "pinter" }}>
                        <div className={`position-4 ${darkname}`}>{positionData.instId ? `${arr[0]}USDT` : ""}</div>
                    </Link>                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_85')}</div>
                    {
                        positionData.pos
                            ?
                            Number(positionData.pos) >= 0
                                ?
                                <div className={`position-4 ${darkname} green`}>{t('futures_145')}</div>
                                :
                                <div className={`position-4 ${darkname} red`}>{t('futures_146')}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_86')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.avgPx ? myData && myData.point ? `${changeNum(Number(positionData.avgPx).toFixed(myData.point))}` : `${changeNum(Number(positionData.avgPx))}` : ""}</div>
                </div>
                <div className='position-2' onClick={() => setPositionAmount([e => !e, positionData.pos && ctVal && ctVal[0] ? `${Math.abs(positionData.pos) * ctVal[0].ctVal}` : ""])} style={{ cursor: "pointer" }}>
                    <div className='position-3'>{t('futures_116')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.pos && ctVal && ctVal[0] ? `${changeNum(Math.abs(positionData.pos) * ctVal[0].ctVal)}` : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_88')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.markPx ? myData && myData.point ? `${changeNum(Number(positionData.markPx).toFixed(myData.point))}` : `${changeNum(Number(positionData.markPx))}` : ""}</div>
                </div>
            </div>
            <div className='position-1'>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_89')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.mgnMode ? positionData.mgnMode == "cross" ? "Cross" : "Isolated" : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_90')}</div>
                    <div className={`position-4 ${darkname}`}>{positionData.instId ? positionData.liqPx === "" ? "-" : myData && myData.point ? `${Number(positionData.liqPx).toFixed(myData.point)}` : Number(positionData.liqPx) : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_91')}(USDT)</div>
                    <div className={`position-4 ${darkname}`}>{positionData.notionalUsd ? `${changeNum(Number(positionData.notionalUsd).toFixed(4, '0'))}` : ""}</div>
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_92')} <FontAwesomeIcon icon={faCrown} /></div>
                    {
                        positionData.upl
                            ?
                            symbol === positionData.instId
                                ?
                                Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)) >= 0
                                    ?
                                    <div className={`position-4 ${darkname} green`}>{changeNum(Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)).toFixed(3))}</div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>{changeNum(Number((currentPrice - Number(positionData.avgPx)) * Number(positionData.pos * ctVal[0].ctVal)).toFixed(3))}</div>
                                :
                                Number(positionData.upl) >= 0
                                    ?
                                    <div className={`position-4 ${darkname} green`}>{changeNum(Number(positionData.uplLastPx).toFixed(3))}</div>
                                    :
                                    <div className={`position-4 ${darkname} red`}>{changeNum(Number(positionData.uplLastPx).toFixed(3))}</div>
                            :
                            <div className={`position-4 ${darkname}`}></div>
                    }
                </div>
                <div className='position-2'>
                    <div className='position-3'>{t('futures_93')}</div>
                    <div className='position-4-1'>
                        <div className='position-table-bg-1-1' onClick={() => FuturesPositionHalfClose(positionData)}>{t('futures_94')}</div>
                        <div className='position-table-bg-1-2' onClick={() => FuturesPositionClose(positionData)}>{t('futures_95')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OpenOrder({ positionbtn, openorder, symbol, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    return (
        <>
            {
                positionbtn
                    ?
                    <div className='futures-order'>
                        <div className='futures-order1'>
                            <div className='futures-order-1'>
                                <div className={`futures-order-1-top ${darkname}`}>{t('futures_96')}</div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "buy") {
                                                        if (a.instId === symbol) {
                                                            if (a.ordType == "conditional" || a.ordType == "trigger") {
                                                                return (
                                                                    <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} type={true} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} type={false} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                                )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='futures-order-1'>
                                <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                                <div className='futures-order-11'>
                                    <table className={`futures-order-3 ${darkname}`}>
                                        <thead>
                                            <tr>
                                                <th><span>{t('futures_97')}</span></th>
                                                <th><span>{t('futures_98')}</span></th>
                                                <th><span>{t('futures_99')}</span></th>
                                                <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                                <th><span>{t('futures_102')}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openorder.map(function (a, i) {
                                                    if (a.side === "sell") {
                                                        if (a.instId === symbol) {
                                                            if (a.ordType == "conditional" || a.ordType == "trigger") {
                                                                return (
                                                                    <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} type={true} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} type={false} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                                )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    )
}

function OpenOrderItem({ item, side, setMessage, type, delay, openorder_cancel, setOpenorder_cancel }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = OKX_Instruments.filter((p) => p.instId === item.instId)

    const { t } = useTranslation();
    let dispatch = useDispatch();
    let arr = item.instId.split('-')

    const Futures_Cancel = () => {
        if (type) FuturesOpenOrderAlgoCancel()
        else FuturesOpenOrderCancel()
    }

    const FuturesOpenOrderCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/cancel", {
                ordId: item.ordId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response.data.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: response.data.msg })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 134, "msg": error }))
                })
        }
    }

    const FuturesOpenOrderAlgoCancel = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setOpenorder_cancel(false)

            await axios.post(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/tpsl/order/cancel", {
                algoId: item.algoId,
                instId: item.instId
            },
                {
                    headers: { Authorization: `Bearer ${jwt}` }
                }).then((response) => {
                    if (Number(response.data.code) >= 0) {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: `${t('message_13')}` })
                    }
                    else {
                        setMessage({ type: "binance", code: Number(response.data.code), msg: response.data.msg })
                    }
                    setTimeout(() => {
                        setOpenorder_cancel(true)
                    }, 500)
                }).catch((error) => {
                    dispatch(check_Error({ "bol": true, "no": 135, "msg": error }))
                })
        }
    }

    return (
        <tr>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{arr[0]}</span><span>USDT</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{item.ordType}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{changeNum(item.px)}</span></td>
            <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"}><span>{type ? "0.00" : changeNum((item.fillSz * ctVal[0].ctVal).toFixed(2))}</span><span></span>/<span>{changeNum((item.sz * item.px * ctVal[0].ctVal).toFixed(2))}</span></td>
            {
                openorder_cancel && delay
                    ?
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }} onClick={() => Futures_Cancel()}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </td>
                    :
                    <td className={side ? "futures-order-3-buy" : "futures-order-3-sell"} style={{ cursor: "pointer" }}>
                        <ClipLoader color="#595959" size='14px' />
                    </td>
            }
        </tr>
    )
}

function DiffOrder({ openorder, darkname, setMessage, delay, openorder_cancel, setOpenorder_cancel }) {
    const { t } = useTranslation();
    return (
        <div className='futures-order'>
            <div className='futures-order1'>
                <div className='futures-order-1'>
                    <div className={`futures-order-1-top ${darkname}`}>{t('futures_96')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "buy") {
                                            if (a.ordType == "conditional" || a.ordType == "trigger") {
                                                return (
                                                    <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} type={true} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <OpenOrderItem key={i} item={a} side={true} setMessage={setMessage} type={false} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                )
                                            }
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='futures-order-1'>
                    <div className={`futures-order-2-top ${darkname}`}>{t('futures_103')}</div>
                    <div className='futures-order-11'>
                        <table className={`futures-order-3 ${darkname}`}>
                            <thead>
                                <tr>
                                    <th><span>{t('futures_97')}</span></th>
                                    <th><span>{t('futures_98')}</span></th>
                                    <th><span>{t('futures_99')}</span></th>
                                    <th><span>{t('futures_100')}</span><span>/</span><span>{t('futures_101')}</span></th>
                                    <th><span>{t('futures_102')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    openorder.map(function (a, i) {
                                        if (a.side === "sell") {
                                            if (a.ordType == "conditional" || a.ordType == "trigger") {
                                                return (
                                                    <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} type={true} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                )
                                            }
                                            else {
                                                return (
                                                    <OpenOrderItem key={i} item={a} side={false} setMessage={setMessage} type={false} delay={delay} openorder_cancel={openorder_cancel} setOpenorder_cancel={setOpenorder_cancel} />
                                                )
                                            }
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Multi() {
    const { t } = useTranslation();
    return (
        <div className='futures-multi'>
            <div className='futures-multi-1'>
                <div>{t('futures_104')}</div>
                <div>{t('futures_105')}</div>
            </div>
            <div className='futures-multi-2'>
                <div className='futures-multi-2-1'>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_107')}</div>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_108')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>1</span></option>
                            <option><span>2</span></option>
                            <option><span>3</span></option>
                            <option><span>4</span></option>
                            <option><span>5</span></option>
                        </select>
                    </div>
                    <div className='futures-multi-2-1-tit'>
                        <div>{t('futures_109')}</div>
                        <select className='futures-multi-2-1-select'>
                            <option><span>X</span> <span>1</span></option>
                            <option><span>X</span> <span>2</span></option>
                            <option><span>X</span> <span>3</span></option>
                            <option><span>X</span> <span>4</span></option>
                            <option><span>X</span> <span>5</span></option>
                        </select>
                    </div>
                </div>
                <div className='futures-multi-2-2'>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 1</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 2</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 3</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 4</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                    <div className='futures-multi-2-2-tit'>
                        <div>{t('futures_110')} 5</div>
                        <div className='futures-multi-2-2-txt'>0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Alarm({ setTradeVolume, tradeVolume }) {
    const { t } = useTranslation();
    let Alert = useSelector((state) => { return state.Alert });
    let dispatch = useDispatch();

    const onChangeTradeAlert = (e) => {
        dispatch(checkAlert({ num: 0, value: e.target.value }))
    }
    const onChangeFilledAlert = (e) => {
        dispatch(checkAlert({ num: 1, value: e.target.value }))
    }
    const onChangeOrderAlert = (e) => {
        dispatch(checkAlert({ num: 2, value: e.target.value }))
    }
    const onChangeCancelAlert = (e) => {
        dispatch(checkAlert({ num: 3, value: e.target.value }))
    }
    const onChangeTradeVolume = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, '')
        setTradeVolume(onlyNumber)
        localStorage.setItem('TradeVolume', onlyNumber)
    }

    return (
        <div className='futures-alarm'>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_111')}</div>
                <select value={Alert[0]} onChange={onChangeTradeAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_112')}</div>
                <input type='text' placeholder='0' value={tradeVolume} onChange={onChangeTradeVolume} />
            </div>
            <div className='futures-alarm-box'>
                <div className='futures-alarm-1'>{t('futures_113')}</div>
                <select value={Alert[1]} onChange={onChangeFilledAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                <div className='futures-alarm-1'>{t('futures_147')}</div>
                <select value={Alert[2]} onChange={onChangeOrderAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select>
                {/* <div className='futures-alarm-1'>Order Cancel Alert</div>
                <select value={Alert[3]} onChange={onChangeCancelAlert}>
                    <option value={"ON"}><span>{t('futures_11')}</span></option>
                    <option value={"OFF"}><span>{t('futures_12')}</span></option>
                </select> */}
            </div>
        </div>
    )
}

export default Fsection8;