import '../../css/futures.css'
import { List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faStar, faRotateLeft, faCoins, faPercent, faDollarSign, faCaretUp, faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { change_OKX_F_Favorite, check_Error } from '../../../store';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"

function Fsection1({ tab, changeTab, USDTSymbolF, symbol }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    const [search, setSearch] = useState("");

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    let filterSymbol = USDTSymbolF.filter((p) => {
        return (p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    })

    return (
        <div className={tab ? `futures-section1 ${darkname}` : `futures-section1-check ${darkname}`}>
            <FontAwesomeIcon icon={faXmark} className={`futures-section1-ico ${darkname}`} onClick={() => changeTab(false)} />
            <div className='futures-section1-box'>
                <div className={`futures-section1-box-position ${darkname}`}>
                    <div className={`futures-section1-box-1 ${darkname}`}>
                        <div className='futures-section1-box-1-1'>
                            <img src='/img/okx_logo.png' className='futures-section1-box-1-img'></img>
                            <div className='futures-section1-box-1-3'>{t('futures_114')}</div>
                        </div>
                    </div>
                    <div className={`futures-section1-box-2 ${darkname}`}>
                        <input type='text' placeholder={`${t('market_0')}`} value={search} onChange={onChange} />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='futures-section1-box-2-ico' />
                    </div>
                </div>
                <BinanceFutures filterSymbol={filterSymbol} darkname={darkname} symbol={symbol} changeTab={changeTab} />
            </div>
        </div>
    )
}

function BinanceFutures({ filterSymbol, darkname, symbol, changeTab }) {
    const [name1, setName1] = useState(0);
    const [name2, setName2] = useState(0);
    const [name3, setName3] = useState(1);
    const [name4, setName4] = useState(0);

    if (name1 === 1) {
        filterSymbol.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));
    }
    if (name1 === 2) {
        filterSymbol.sort((a, b) => Number(a.lastPrice) - Number(b.lastPrice));
    }
    if (name2 === 1) {
        filterSymbol.sort((a, b) => {
            if (a.priceChangePercent < b.priceChangePercent) return 1;
            if (a.priceChangePercent > b.priceChangePercent) return -1;
        });
    }
    if (name2 === 2) {
        filterSymbol.sort((a, b) => {
            if (a.priceChangePercent > b.priceChangePercent) return 1;
            if (a.priceChangePercent < b.priceChangePercent) return -1;
        });
    }
    if (name3 === 1) {
        filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    }
    if (name3 === 2) {
        filterSymbol.sort((a, b) => Number(a.volume) * Number(a.lastPrice) - Number(b.volume) * Number(b.lastPrice));
    }

    if (name4 === 1) {
        filterSymbol.sort((a, b) => {
            if (a.ccy > b.ccy) return 1;
            if (a.ccy < b.ccy) return -1;
        });
    }
    if (name4 === 2) {
        filterSymbol.sort((a, b) => {
            if (a.ccy < b.ccy) return 1;
            if (a.ccy > b.ccy) return -1;
        });
    }

    filterSymbol.sort((a, b) => b.favorite - a.favorite);

    return (
        <div className={`futures-section1-box-3 ${darkname}`}>
            <div className='futures-3-table'>
                <div className='futures-3-head'>
                    <div className='futures-3-th' >
                        <span className={name4 === 0 ? '' : 'orange'} onClick={() => { { setName1(0); setName2(0); setName3(0); setName4(0) } }}><FontAwesomeIcon icon={faRotateLeft} /></span>
                    </div>
                    <div className='futures-3-th' onClick={() => { { setName1(name1 => (name1 + 1) % 3); setName4(0); setName2(0); setName3(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name1 === 0 ? '' : 'orange'}><FontAwesomeIcon icon={faCoins} /></span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name1 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className='futures-3-th' onClick={() => { { setName2(name2 => (name2 + 1) % 3); setName1(0); setName4(0); setName3(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name2 === 0 ? '' : 'orange'} ><FontAwesomeIcon icon={faPercent} /></span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name2 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className='futures-3-th' onClick={() => { { setName3(name3 => (name3 + 1) % 3); setName1(0); setName2(0); setName4(0) } }} style={{ cursor: "pointer" }}>
                        <span className={name3 === 0 ? '' : 'orange'} ><FontAwesomeIcon icon={faDollarSign} /></span>
                        <span>
                            <span className="index_sort-btn__eWsx8">
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 2 ? "fill-color" : ""}`} d="M0.342163 3L3.77567 3C3.96395 3 4.11658 2.85941 4.11658 2.68599C4.11658 2.62399 4.09665 2.56339 4.05932 2.5118L2.34257 0.139848C2.23813 -0.00444984 2.02647 -0.0434422 1.86981 0.0527565C1.83236 0.0757532 1.80023 0.105353 1.77526 0.139848L0.0585074 2.5118C-0.0459313 2.6561 -0.00359917 2.85106 0.153059 2.94726C0.20906 2.98165 0.274858 3 0.342163 3Z" fill={"#999"}>
                                    </path>
                                </svg>
                                <svg width="5" height="4" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${name3 === 1 ? "fill-color" : ""}`} d="M3.77442 0H0.340911C0.152631 0 0 0.140589 0 0.314014C0 0.376008 0.0199222 0.436615 0.0572559 0.488197L1.77401 2.86015C1.87845 3.00445 2.09011 3.04344 2.24677 2.94724C2.28422 2.92425 2.31635 2.89465 2.34132 2.86015L4.05807 0.488197C4.16251 0.343899 4.12018 0.148937 3.96352 0.0527385C3.90752 0.0183503 3.84172 0 3.77442 0Z" fill={"#999"}>
                                    </path>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>
                <div className='futures-3-body'>
                    <MyListComponent data={filterSymbol} darkname={darkname} symbol={symbol} changeTab={changeTab} />
                </div>
            </div>
        </div>
    )
}

function MyListComponent({ data, darkname, symbol, changeTab }) {
    const rowRenderer = ({ index, key, style }) => {
        const post = data[index]
        return (
            <BinanceFuturesItem item={post} darkname={darkname} symbol={symbol} key={key} style={style} changeTab={changeTab} />
        );
    };

    return (
        <List
            width={205} // 전체 크기
            height={720} // 전체 높이
            rowCount={data.length} // 항목 개수
            rowHeight={45} // 항목 높이
            rowRenderer={rowRenderer} // 항목을 렌더링할 때 쓰는 함수
            list={data} // 배열
            style={{ outline: 'none', paddingRight: "5px" }} // List에 기본 적용되는 outline 스타일 제거
        />
    );
}

function BinanceFuturesItem({ item, darkname, symbol, key, style, changeTab }) {
    let dispatch = useDispatch();
    const [click, setClick] = useState(false)

    const onClick = () => {
        setClick(true)
        setTimeout(() => {
            setClick(false)
        }, 100)
    }

    const Favorite = async () => {
        let objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;
            await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/bookmark", {
                exchange: "okx",
                type: 'futures',
                coin: item.ccy
            }, {
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                dispatch(change_OKX_F_Favorite(item.ccy))
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 118, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-3-tr ${darkname} ${symbol === item.symbol ? `on ${click ? "click" : ""} ` : ``}`} key={key} style={style}>
            <div className='futures-item-body-ico' onClick={() => Favorite()}>
                {
                    item.favorite
                        ?
                        <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} className='color' />
                        :
                        <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
                }
            </div>
            <Link to={`/futures/okx/${item.symbol}`} style={{ textDecoration: "none", width: "100%", display: "flex", flexDirection: "column", gap: "5px", justifyContent: "center" }} onClick={() => { onClick(); changeTab(false) }} >
                <div className='futures-item-box-1'>
                    <div className={`futures-item-body ${darkname}`}>
                        <div style={{ fontSize: "13px", paddingLeft: "18px" }}>
                            <span>{item.ccy}</span><span>USDT</span>
                        </div>
                    </div>
                    <div>
                        <span>{changeNum((Number(item.lastPrice) * Number(item.volume) / 1000000).toFixed(2))}</span><span>M</span>
                    </div>
                </div>
                <div className='futures-item-box-2'>
                    <div style={{ paddingLeft: "10px" }}>
                        {
                            item.priceChangePercent >= 0
                                ?
                                <div className="green"><span>+</span><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                                :
                                <div className="red"><span>{Number(item.priceChangePercent).toFixed(2)}</span><span>%</span></div>
                        }
                    </div>
                    <div>
                        {
                            item.priceChangePercent >= 0
                                ?
                                <div className="green"><FontAwesomeIcon icon={faCaretUp} style={{ paddingRight: "5px" }} />{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                                :
                                <div className="red"><FontAwesomeIcon icon={faCaretDown} style={{ paddingRight: "5px" }} />{changeNum(Number(item.lastPrice).toFixed(item.point))}</div>
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Fsection1;