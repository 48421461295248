import { useSelector } from 'react-redux';
import '../css/modal.css'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

function Wallet_Message({ message, buyModal, setBuyModal, setW_confirm }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        if (message.code === 0) {
            setBuyModal(true)
        }
    }, [message]);

    return (
        <>
            {
                buyModal
                    ?
                    <>
                        <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(3px)" }}></div>
                        <div className={`message-modal ${darkname}`} style={{ alignItems: "flex-start" }}>
                            {/* {
                                message && message.msg
                                    ?
                                    message.msg.map(function (a, i) {
                                        return (
                                            <div className="wallet-message-modal-1">{a}</div>
                                        )
                                    })
                                    :
                                    ""
                            } */}
                            <div className='message-modal-top'>
                                <div className='message-modal-top-1'>Instructions</div>
                                <div className='message-modal-top-2' onClick={() => setBuyModal(false)}>close</div>
                            </div>
                            <div className="message-modal-2">{t('message_10')}</div>
                            <div className='message-modal-btn' onClick={() => setW_confirm(true)}>{t('message_3')}</div>
                        </div>
                    </>
                    :
                    ""
            }

        </>
    )
}

export default Wallet_Message;