import '../css/history.css'
import axios from 'axios';
import Page from '../Page';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faStar, faChartSimple, faComputerMouse } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, checkSelectType, check_Error } from '../../store';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../common/function"

import { Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function History() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let API_Address = useSelector((state) => { return state.API_Address.value })
    const darkname = dark ? 'dark' : "";
    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [personal_fee, setPersonal_fee] = useState(0.5)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else setTab(location.state.num)
    }, [location])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1080) {
                setSlidesPerView(1);
            }
            else {
                setSlidesPerView(0);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 화면 크기에 따른 슬라이드 수 설정

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    useEffect(() => {
        document.title = `${API_Address == "rf" ? "RF-Rev│One-Stop Trading Platform - Crypto Trading Universe" : "goEx│One-Stop Trading Platform - Crypto Trading Universe"}`;
    }, []);

    return (
        <div className={`history ${darkname}`} 
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            <div className='history-container'>
                <div className='history-wrap'>
                    <div className='history-left'>
                        {
                            slidesPerView === 0
                                ?
                                <ul>
                                    <li>
                                        <div className='history-tit'>{t('history_0')}</div>
                                    </li>
                                    <li>
                                        <Link to={`/history`} state={{ num: 0 }} >
                                            <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(0)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 15 15">
                                                        <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                        <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                        <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                        <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                        <g>
                                                            <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                            <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                            <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                            <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                            <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                        </g>
                                                    </svg>
                                                    {t('header_16')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/history`} state={{ num: 1 }} >
                                            <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(1)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 300.8 354.2">
                                                        <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "></path>
                                                        <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "></path>
                                                    </svg>
                                                    {t('header_18')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className='history-tit'>{t('history_1')}</div>
                                    </li>
                                    <li>
                                        <Link to={`/history`} state={{ num: 2 }} >
                                            <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(2)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 15 15">
                                                        <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                        <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                        <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                        <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                        <g>
                                                            <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                            <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                            <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                            <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                            <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                        </g>
                                                    </svg>
                                                    {t('header_20')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/history`} state={{ num: 3 }} >
                                            <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(3)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 300.8 354.2">
                                                        <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "></path>
                                                        <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "></path>
                                                    </svg>
                                                    {t('header_22')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/history`} state={{ num: 4 }} >
                                            <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(4)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 15 15">
                                                        <rect className="st1" x="1.78" y="11.76" width="11.31" height="1.11" />
                                                        <rect className="st1" x="1.78" y="9.33" width="11.31" height="1.11" />
                                                        <rect className="st1" x="1.78" y="6.9" width="11.31" height="1.11" />
                                                        <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                        <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                        <path className="st1" d="m7.62,2.47V.63c0-.19-.15-.34-.34-.34H.53c-.19,0-.34.15-.34.34v13.49c0,.19.15.34.34.34h13.97c.15,0,.26-.12.26-.26V2.81c0-.19-.15-.34-.34-.34h-6.79Zm6.03,10.89H1.3V1.4h5.22v2.18h0s7.14,0,7.14,0v9.78Z" />
                                                        <path className="st0" d="m5.96,4.36c0,.85-.51,1.59-1.24,1.92-.14.06-.29.11-.45.15-.14.03-.28.04-.43.04-1.17,0-2.12-.95-2.12-2.12s.95-2.11,2.11-2.12v2.12h2.13Z" />
                                                        <path className="st0" d="m4.85,3.93c-.37-.02-.6-.22-.62-.54h.28c.02.18.16.27.34.29v-.61l-.09-.02c-.3-.08-.48-.23-.48-.49,0-.29.24-.5.57-.53v-.21h.15v.21c.34.02.58.23.59.51h-.27c-.02-.15-.14-.25-.31-.27v.57l.08.02c.24.06.53.19.53.52,0,.29-.22.51-.61.54v.2h-.15v-.2Zm0-1.12v-.53c-.18.02-.29.13-.29.27,0,.16.15.23.29.27Zm.48.58c0-.16-.15-.22-.33-.28v.57c.2-.02.33-.13.33-.3Z" />
                                                    </svg>
                                                    {t('header_24')}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to={`/history`} state={{ num: 5 }} >
                                            <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(5)}>
                                                <div className='history-tit-box'>
                                                    <svg viewBox="0 0 364.4 364.5">
                                                        <path d="M364.4,170.9c0,7.8,0,15.7,0,23.5c-0.8,5.7-1.4,11.4-2.3,17.1c-7.1,41.6-26.1,76.9-57.2,105.4c-27.3,25-59.4,40.2-96,45.6
                        c-4.9,0.7-9.7,1.3-14.6,2c-8.3,0-16.6,0-24.9,0c-0.6-0.2-1.2-0.6-1.9-0.6c-22.8-1.8-44.5-7.7-65-17.7
                        C28,309.8-12.4,228.7,3.4,147.1C18.6,68.6,84.9,8.2,164.2,0.9c38.7-3.6,75.4,3.7,108.9,23.6c48.5,28.7,78.1,71,88.5,126.5
                        C362.9,157.5,363.5,164.2,364.4,170.9z M180.8,306.9C180.8,306.9,180.8,306.9,180.8,306.9c2.6,0,5.2,0,7.8,0
                        c5.8-0.1,7.9-2.1,8.1-8c0.2-4.6,0.3-9.3,0.2-13.9c-0.1-7.8,1-9.5,8.4-11.7c12.1-3.7,22.3-10.3,30.1-20.3
                        c20.3-26.1,12.9-63.5-16.4-80.7c-10-5.9-21.2-9.8-31.7-14.9c-6.3-3.1-12.8-6.3-18.3-10.7c-8.7-7-7-19,3.1-23.9
                        c3.7-1.8,8.1-2.6,12.3-2.9c13.4-0.9,26.1,1.9,38.2,7.7c5.8,2.8,7.8,1.9,9.7-4.2c1.9-6,3.6-12,5.3-18c2.3-7.9,1.4-10.3-6.4-12.9
                        c-8.4-2.8-17-4.8-25.6-6.9c-5.7-1.4-6.9-2.4-7.1-8.3c-0.1-4,0-8.1-0.3-12.1c-0.5-5.8-2.1-7.2-7.8-7.4c-5.2-0.1-10.4-0.2-15.7-0.1
                        c-5.8,0.1-7.3,1.7-7.5,7.5c-0.1,5-0.2,10-0.2,14.9c0,4.1-1.8,6.6-5.7,8c-3.9,1.4-7.8,2.9-11.5,4.6c-24.6,11.6-36.6,38.6-28.3,63.5
                        c4.5,13.6,14.3,22.9,26.5,29.4c10.8,5.7,22.5,10,33.5,15.3c5.4,2.6,10.7,5.7,15.3,9.5c5,4.2,6.9,10.2,5.2,16.7
                        c-1.9,7.4-7.5,11.2-14.3,13.5c-11.2,3.8-22.4,2-33.2-1.1c-7.6-2.1-14.8-5.5-22.2-8.4c-4-1.6-6-1.3-7.2,2.3c-2.7,8.4-5,17-7.3,25.6
                        c-0.8,2.8,0.4,5.2,2.9,6.7c2.6,1.5,5.3,3.3,8.2,4.1c9.3,2.7,18.7,5.1,28.1,7.3c6.6,1.5,7.3,1.9,7.4,8.5c0.1,4.6,0,9.3,0.2,13.9
                        c0.3,5.3,2.5,7.4,7.8,7.5C175.3,307,178.1,306.9,180.8,306.9z"></path>
                                                    </svg>
                                                    Insurance Fund
                                                </div>
                                            </div>
                                        </Link>
                                    </li> */}
                                </ul>
                                :
                                <div style={{ position: "relative" }}>
                                    <div className={`box-pre ${darkname}`}></div>
                                    <div className={`box-next ${darkname}`}></div>
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={10}
                                        initialSlide={tab}
                                        freeMode={true}
                                        centeredSlides={true}
                                        slideToClickedSlide={true}
                                        centeredSlidesBounds={true}
                                        modules={[FreeMode, Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 0 }} >
                                                <div className={tab === 0 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(0)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 15 15">
                                                            <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                            <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                            <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                            <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                            <g>
                                                                <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                                <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                                <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                                <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                                <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                            </g>
                                                        </svg>
                                                        {t('header_16')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 1 }} >
                                                <div className={tab === 1 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(1)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 300.8 354.2">
                                                            <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "></path>
                                                            <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "></path>
                                                        </svg>
                                                        {t('header_18')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 2 }} >
                                                <div className={tab === 2 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(2)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 15 15">
                                                            <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                            <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                            <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                            <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                            <g>
                                                                <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                                <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                                <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                                <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                                <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                            </g>
                                                        </svg>
                                                        {t('header_20')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 3 }} >
                                                <div className={tab === 3 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(3)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 300.8 354.2">
                                                            <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "></path>
                                                            <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "></path>
                                                        </svg>
                                                        {t('header_22')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 4 }} >
                                                <div className={tab === 4 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(4)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 15 15">
                                                            <rect className="st1" x="1.78" y="11.76" width="11.31" height="1.11" />
                                                            <rect className="st1" x="1.78" y="9.33" width="11.31" height="1.11" />
                                                            <rect className="st1" x="1.78" y="6.9" width="11.31" height="1.11" />
                                                            <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                            <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                            <path className="st1" d="m7.62,2.47V.63c0-.19-.15-.34-.34-.34H.53c-.19,0-.34.15-.34.34v13.49c0,.19.15.34.34.34h13.97c.15,0,.26-.12.26-.26V2.81c0-.19-.15-.34-.34-.34h-6.79Zm6.03,10.89H1.3V1.4h5.22v2.18h0s7.14,0,7.14,0v9.78Z" />
                                                            <path className="st0" d="m5.96,4.36c0,.85-.51,1.59-1.24,1.92-.14.06-.29.11-.45.15-.14.03-.28.04-.43.04-1.17,0-2.12-.95-2.12-2.12s.95-2.11,2.11-2.12v2.12h2.13Z" />
                                                            <path className="st0" d="m4.85,3.93c-.37-.02-.6-.22-.62-.54h.28c.02.18.16.27.34.29v-.61l-.09-.02c-.3-.08-.48-.23-.48-.49,0-.29.24-.5.57-.53v-.21h.15v.21c.34.02.58.23.59.51h-.27c-.02-.15-.14-.25-.31-.27v.57l.08.02c.24.06.53.19.53.52,0,.29-.22.51-.61.54v.2h-.15v-.2Zm0-1.12v-.53c-.18.02-.29.13-.29.27,0,.16.15.23.29.27Zm.48.58c0-.16-.15-.22-.33-.28v.57c.2-.02.33-.13.33-.3Z" />
                                                        </svg>
                                                        {t('header_24')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            <Link to={`/history`} state={{ num: 5 }} >
                                                <div className={tab === 5 ? `history-txt current ${darkname}` : `history-txt ${darkname}`} onClick={() => setTab(5)}>
                                                    <div className='history-tit-box'>
                                                        <svg viewBox="0 0 364.4 364.5">
                                                            <path d="M364.4,170.9c0,7.8,0,15.7,0,23.5c-0.8,5.7-1.4,11.4-2.3,17.1c-7.1,41.6-26.1,76.9-57.2,105.4c-27.3,25-59.4,40.2-96,45.6
                        c-4.9,0.7-9.7,1.3-14.6,2c-8.3,0-16.6,0-24.9,0c-0.6-0.2-1.2-0.6-1.9-0.6c-22.8-1.8-44.5-7.7-65-17.7
                        C28,309.8-12.4,228.7,3.4,147.1C18.6,68.6,84.9,8.2,164.2,0.9c38.7-3.6,75.4,3.7,108.9,23.6c48.5,28.7,78.1,71,88.5,126.5
                        C362.9,157.5,363.5,164.2,364.4,170.9z M180.8,306.9C180.8,306.9,180.8,306.9,180.8,306.9c2.6,0,5.2,0,7.8,0
                        c5.8-0.1,7.9-2.1,8.1-8c0.2-4.6,0.3-9.3,0.2-13.9c-0.1-7.8,1-9.5,8.4-11.7c12.1-3.7,22.3-10.3,30.1-20.3
                        c20.3-26.1,12.9-63.5-16.4-80.7c-10-5.9-21.2-9.8-31.7-14.9c-6.3-3.1-12.8-6.3-18.3-10.7c-8.7-7-7-19,3.1-23.9
                        c3.7-1.8,8.1-2.6,12.3-2.9c13.4-0.9,26.1,1.9,38.2,7.7c5.8,2.8,7.8,1.9,9.7-4.2c1.9-6,3.6-12,5.3-18c2.3-7.9,1.4-10.3-6.4-12.9
                        c-8.4-2.8-17-4.8-25.6-6.9c-5.7-1.4-6.9-2.4-7.1-8.3c-0.1-4,0-8.1-0.3-12.1c-0.5-5.8-2.1-7.2-7.8-7.4c-5.2-0.1-10.4-0.2-15.7-0.1
                        c-5.8,0.1-7.3,1.7-7.5,7.5c-0.1,5-0.2,10-0.2,14.9c0,4.1-1.8,6.6-5.7,8c-3.9,1.4-7.8,2.9-11.5,4.6c-24.6,11.6-36.6,38.6-28.3,63.5
                        c4.5,13.6,14.3,22.9,26.5,29.4c10.8,5.7,22.5,10,33.5,15.3c5.4,2.6,10.7,5.7,15.3,9.5c5,4.2,6.9,10.2,5.2,16.7
                        c-1.9,7.4-7.5,11.2-14.3,13.5c-11.2,3.8-22.4,2-33.2-1.1c-7.6-2.1-14.8-5.5-22.2-8.4c-4-1.6-6-1.3-7.2,2.3c-2.7,8.4-5,17-7.3,25.6
                        c-0.8,2.8,0.4,5.2,2.9,6.7c2.6,1.5,5.3,3.3,8.2,4.1c9.3,2.7,18.7,5.1,28.1,7.3c6.6,1.5,7.3,1.9,7.4,8.5c0.1,4.6,0,9.3,0.2,13.9
                        c0.3,5.3,2.5,7.4,7.8,7.5C175.3,307,178.1,306.9,180.8,306.9z"></path>
                                                        </svg>
                                                        Insurance Fund
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                        }
                    </div>
                    {
                        tab === 0
                            ?
                            <SpotSummary darkname={darkname} dark={dark} SelectType={SelectType} personal_fee={personal_fee} />
                            :
                            tab === 1
                                ?
                                <SpotDetail darkname={darkname} dark={dark} SelectType={SelectType} personal_fee={personal_fee} />
                                :
                                tab === 2
                                    ?
                                    <FuturesSummary darkname={darkname} dark={dark} SelectType={SelectType} personal_fee={personal_fee} />
                                    :
                                    tab === 3
                                        ?
                                        <FuturesDetail darkname={darkname} dark={dark} SelectType={SelectType} personal_fee={personal_fee} />
                                        :
                                        tab === 4
                                            ?
                                            <FuturesFee darkname={darkname} dark={dark} SelectType={SelectType} />
                                            :
                                            <FuturesFund darkname={darkname} dark={dark} SelectType={SelectType} />
                    }
                </div>
            </div>
        </div>
    )
}

function SpotSummary({ darkname, dark, SelectType, personal_fee }) {
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [spotOrderHistory, setSpotOrderHistory] = useState([]);
    const [spotsum, setSpotsum] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalSum, setTotalSum] = useState(0)

    let dispatch = useDispatch();

    useEffect(() => {
        SpotOrderHistory()
    }, [selectedDate, selectedDate1])

    const SpotOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/spot/trade-history`, {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86400000
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setSpotOrderHistory(response.data.data)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 104, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalS = 0

        if (spotOrderHistory) {
            for (let i = 0; i < spotOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (spotOrderHistory[i].instId === summary[j].instId) {
                        check = false
                        summary[j].volume += Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz)
                        summary[j].pnl += 0
                        summary[j].fee += Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1)
                    }
                }
                if (check) {
                    summary.push({
                        instId: spotOrderHistory[i].instId, volume: Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz), pnl: 0,
                        fee: Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1)
                    })
                }
                totalV += Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz)
                totalS += Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1) * (1 - personal_fee)
            }
        }

        setSpotsum(summary)
        setTotalVolume(totalV)
        setTotalSum(totalS)
    }, [spotOrderHistory])

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_16')}</div>
                <p className='history-right-top-2'>{t('header_17')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(0))}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('exchange_1')} {t('header_16')}</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        {/* <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => SpotOrderHistory()}>{t('market_0')}</div>
                        </div> */}
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-3'>
                        <div className='history-right-bottom-3-1'>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_6')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalVolume.toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_7')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalSum.toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1 first'>{t('history_8')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_9')} (USDT)</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_10')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_11')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_12')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                spotsum && spotsum.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    spotsum.map(function (a, i) {
                                        return (
                                            <SpotSummaryItem key={i} item={a} type={"spot"} darkname={darkname} personal_fee={personal_fee} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SpotSummaryItem({ item, type, darkname, personal_fee }) {
    let arr = item.instId.split('-')

    return (
        <div className={`history-right-bottom-4-bottom-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1 first'>{arr[0]}{type == "futures" ? "USDT" : "/USDT"}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(item.volume.toFixed(4))}</div>
            {
                type == "futures"
                    ?
                    <div className={`history-right-bottom-4-bottom-2-1 ${item.pnl >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(item.pnl).toFixed(4))}</div>
                    :
                    ""
            }
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(Math.abs(item.fee).toFixed(4))}</div>
            <div className='history-right-bottom-4-bottom-2-1 green'>{changeNum(Math.abs(item.fee * (personal_fee)).toFixed(4))}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum((item.pnl + item.fee * (1 - personal_fee)).toFixed(4))}</div>
        </div>
    )
}

function SpotDetail({ darkname, dark, SelectType, personal_fee }) {
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [spotOrderHistory, setSpotOrderHistory] = useState([]);
    const [spotsum, setSpotsum] = useState([])
    const [selectsym, setSelectsym] = useState("")
    const [checksym, setChecksym] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalPnl, setTotalPnl] = useState(0)
    const [totalFee, setTotalFee] = useState(0)

    let dispatch = useDispatch();

    useEffect(() => {
        SpotOrderHistory()
    }, [selectedDate, selectedDate1])

    const SpotOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/spot/trade-history`, {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86400000
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setSpotOrderHistory(response.data.data)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 105, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalP = 0
        let totalF = 0

        if (spotOrderHistory) {
            for (let i = 0; i < spotOrderHistory.length; i++) {
                let check = true
                for (let j = 0; j < summary.length; j++) {
                    if (spotOrderHistory[i].instId === summary[j].instId) {
                        check = false
                        summary[j].volume += Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz)
                        summary[j].pnl += 0
                        summary[j].fee += Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1)
                    }
                }
                if (check) {
                    summary.push({
                        instId: spotOrderHistory[i].instId, volume: Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz), pnl: 0,
                        fee: Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1)
                    })
                }
                totalV += Number(spotOrderHistory[i].fillPx) * Number(spotOrderHistory[i].fillSz)
                totalP += 0
                totalF += Number(spotOrderHistory[i].fee) * (spotOrderHistory[i].side == "buy" ? spotOrderHistory[i].fillPx : 1)
            }
        }

        setSpotsum(summary)
        setChecksym(Array.from({ length: summary.length }, () => false))
        setTotalVolume(totalV)
        setTotalPnl(totalP)
        setTotalFee(totalF)
    }, [spotOrderHistory])

    const handleButtonClick = (e) => {
        setChecksym(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_18')}</div>
                <p className='history-right-top-2'>{t('header_19')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(0))}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('exchange_0')} {t('header_18')}</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        {/* <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => { SpotOrderHistory(); setChecksym(false) }}>{t('market_0')}</div>
                        </div> */}
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-3'>
                        <div className='history-right-bottom-3-1'>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_6')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalVolume.toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_25')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(Math.abs(totalFee).toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_14')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum((Math.abs(totalFee) * (personal_fee)).toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_7')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum((totalPnl + totalFee * (1 - personal_fee)).toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1 first'>{t('history_8')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_9')} (USDT)</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_10')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_11')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_12')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                spotsum && spotsum.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    spotsum.map(function (a, i) {
                                        return (
                                            <SpotDetailItem key={i} num={i} item={a} setSelectsym={setSelectsym} selectsym={selectsym} checksym={checksym} type={"spot"} darkname={darkname} OrderHistory={spotOrderHistory} handleButtonClick={handleButtonClick} personal_fee={personal_fee} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SpotDetailItem({ num, item, setSelectsym, selectsym, checksym, type, darkname, OrderHistory, handleButtonClick, personal_fee }) {
    const { t } = useTranslation();
    let arr = item.instId.split('-')

    const [page, setPage] = useState(1)

    let filterOrderHistory_symbol = OrderHistory.filter((item) => item.instId === selectsym)
    let filterOrderHistory = filterOrderHistory_symbol.slice((page - 1) * 10, (page) * 10)

    return (
        <>
            <div className={selectsym === item.instId && checksym[num] ? `history-right-bottom-4-bottom-2 on ${darkname}` : `history-right-bottom-4-bottom-2 ${darkname}`}>
                <div className='history-right-bottom-4-bottom-2-1 first' onClick={() => { setSelectsym(item.instId); handleButtonClick(num) }} style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faComputerMouse} style={{ color: "#979797" }} />
                    <span style={{ marginLeft: "10px" }}>{arr[0]}{type == "futures" ? "USDT" : "/USDT"}</span>
                </div>
                <div className='history-right-bottom-4-bottom-2-1'>{changeNum(item.volume.toFixed(4))}</div>
                {
                    type == "futures"
                        ?
                        <div className={`history-right-bottom-4-bottom-2-1 ${item.pnl >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(item.pnl).toFixed(4))}</div>
                        :
                        ""
                }
                <div className='history-right-bottom-4-bottom-2-1'>{changeNum(Math.abs(item.fee).toFixed(4))}</div>
                <div className='history-right-bottom-4-bottom-2-1 green'>{changeNum(Math.abs(item.fee * (personal_fee)).toFixed(4))}</div>
                <div className='history-right-bottom-4-bottom-2-1'>{changeNum((item.pnl + item.fee * (1 - personal_fee)).toFixed(4))}</div>
            </div>
            {
                checksym[num]
                    ?
                    <div className={`history-right-bottom-4-bottom-3 ${darkname}`}>
                        <table>
                            <thead>
                                <tr>
                                    <th><span>{t('history_15')}</span></th>
                                    <th><span>{t('history_16')}</span></th>
                                    <th><span>{t('history_17')}</span></th>
                                    <th><span>{t('history_18')}</span></th>
                                    {
                                        type === "futures"
                                            ?
                                            <th><span>{t('history_21')}</span></th>
                                            :
                                            ""
                                    }
                                    <th><span>{t('history_19')}</span></th>
                                    <th><span>{t('history_20')}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterOrderHistory.map(function (a, i) {
                                        if (a.instId === selectsym) {
                                            return (
                                                <SpotDetailTable key={i} item={a} personal_fee={personal_fee} type={type} />
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                        <Page initialData={filterOrderHistory_symbol} page={page} setPage={setPage} maxFirstPage={10} />
                    </div>
                    :
                    ""
            }
        </>
    )
}

function SpotDetailTable({ item, personal_fee, type }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    let ctVal = 1;
    if (type == "futures") ctVal = OKX_Instruments.filter((p) => p.instId === item.instId)

    const date = UTCTimestamp(new Date(Number(item.ts)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <tr>
            <td><span>{formattedDate}</span></td>
            <td><span>{item.side}</span></td>
            <td><span>{changeNum(item.fillPx)}</span></td>
            {
                type == "futures"
                    ?
                    <>
                        <td><span>{changeNum(Number(item.fillSz) * ctVal[0].ctVal)}/{changeNum((Number(item.fillSz) * Number(item.fillPx) * ctVal[0].ctVal).toFixed(4))}</span></td>
                        <td className={`${item.fillPnl >= 0 ? "green" : "red"}`}><span>{changeNum(Math.abs(Number(item.fillPnl)).toFixed(4))}</span></td>
                        <td><span>{changeNum(Math.abs(Number(item.fee)).toFixed(4))}</span></td>
                        <td className='green'><span>{changeNum(Math.abs(Number(item.fee) * (personal_fee)).toFixed(4))}</span></td>
                    </>
                    :
                    <>
                        <td><span>{changeNum(item.fillSz)}/{changeNum((Number(item.fillSz) * Number(item.fillPx)).toFixed(4))}</span></td>
                        <td><span>{changeNum(Math.abs(Number(item.fee) * (item.side == "buy" ? Number(item.fillPx) : 1)).toFixed(4))}</span></td>
                        <td className='green'><span>{changeNum(Math.abs(Number(item.fee) * (item.side == "buy" ? Number(item.fillPx) : 1) * (personal_fee)).toFixed(4))}</span></td>
                    </>
            }
        </tr>
    )
}

function FuturesSummary({ darkname, dark, SelectType, personal_fee }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [futuresOrderHistory, setFuturesOrderHistory] = useState([]);
    const [futuressum, setFuturessum] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalSum, setTotalSum] = useState(0)

    let dispatch = useDispatch();

    useEffect(() => {
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/trade-history", {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86400000
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setFuturesOrderHistory(response.data.data)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 106, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalS = 0

        if (futuresOrderHistory) {
            for (let i = 0; i < futuresOrderHistory.length; i++) {
                let check = true

                let ctVal = OKX_Instruments.filter((item) => item.instId === futuresOrderHistory[i].instId)

                for (let j = 0; j < summary.length; j++) {
                    if (futuresOrderHistory[i].instId === summary[j].instId) {
                        check = false
                        summary[j].volume += Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal
                        summary[j].pnl += Number(futuresOrderHistory[i].fillPnl)
                        summary[j].fee += Number(futuresOrderHistory[i].fee)
                    }
                }
                if (check) {
                    summary.push({ instId: futuresOrderHistory[i].instId, volume: Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal, pnl: Number(futuresOrderHistory[i].fillPnl), fee: Number(futuresOrderHistory[i].fee) })
                }
                totalV += Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal
                totalS += Number(futuresOrderHistory[i].fillPnl) + Number(futuresOrderHistory[i].fee) * (1 - personal_fee)
            }
        }

        setFuturessum(summary)
        setTotalVolume(totalV)
        setTotalSum(totalS)
    }, [futuresOrderHistory])

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_20')}</div>
                <p className='history-right-top-2'>{t('header_21')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(0))}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('exchange_1')} {t('header_20')}</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        {/* <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => FuturesOrderHistory()}>{t('market_0')}</div>
                        </div> */}
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-3'>
                        <div className='history-right-bottom-3-1'>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_6')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalVolume.toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_7')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalSum.toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1 first'>{t('history_8')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_9')} (USDT)</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_21')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_10')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_11')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_12')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                futuressum && futuressum.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    futuressum.map(function (a, i) {
                                        return (
                                            <SpotSummaryItem key={i} item={a} type={"futures"} darkname={darkname} personal_fee={personal_fee} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FuturesDetail({ darkname, dark, SelectType, personal_fee }) {
    let OKX_Instruments = useSelector((state) => { return state.OKX_Instruments });
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [futuresOrderHistory, setFuturesOrderHistory] = useState([]);
    const [futuressum, setFuturessum] = useState([])
    const [selectsym, setSelectsym] = useState("")
    const [checksym, setChecksym] = useState([])
    const [totalVolume, setTotalVolume] = useState(0)
    const [totalPnl, setTotalPnl] = useState(0)
    const [totalFee, setTotalFee] = useState(0)

    let dispatch = useDispatch();

    useEffect(() => {
        FuturesOrderHistory()
    }, [selectedDate, selectedDate1])

    const FuturesOrderHistory = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + "/api/v1/trade/okx/swap/trade-history", {
                params: {
                    begin: changeTime(selectedDate),
                    end: changeTime(selectedDate1) + 86400000
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setFuturesOrderHistory(response.data.data)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 107, "msg": error }))
            })
        }
    }

    useEffect(() => {
        let summary = []
        let totalV = 0
        let totalP = 0
        let totalF = 0

        if (futuresOrderHistory) {
            for (let i = 0; i < futuresOrderHistory.length; i++) {
                let check = true

                let ctVal = OKX_Instruments.filter((item) => item.instId === futuresOrderHistory[i].instId)

                for (let j = 0; j < summary.length; j++) {
                    if (futuresOrderHistory[i].instId === summary[j].instId) {
                        check = false
                        summary[j].volume += Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal
                        summary[j].pnl += Number(futuresOrderHistory[i].fillPnl)
                        summary[j].fee += Number(futuresOrderHistory[i].fee)
                    }
                }
                if (check) {
                    summary.push({ instId: futuresOrderHistory[i].instId, volume: Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal, pnl: Number(futuresOrderHistory[i].fillPnl), fee: Number(futuresOrderHistory[i].fee) })
                }
                totalV += Number(futuresOrderHistory[i].fillPx) * Number(futuresOrderHistory[i].fillSz) * ctVal[0].ctVal
                totalP += Number(futuresOrderHistory[i].fillPnl)
                totalF += Number(futuresOrderHistory[i].fee)
            }
        }

        setFuturessum(summary)
        setChecksym(Array.from({ length: summary.length }, () => false))
        setTotalVolume(totalV)
        setTotalPnl(totalP)
        setTotalFee(totalF)
    }, [futuresOrderHistory])

    const handleButtonClick = (e) => {
        setChecksym(prevState => {
            const newState = [...prevState];
            for (let i = 0; i < newState.length; i++) {
                if (i === e && !newState[i]) newState[i] = true
                else newState[i] = false
            }
            return newState;
        });
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_22')}</div>
                <p className='history-right-top-2'>{t('header_23')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => { dispatch(checkSelectType(1)) }}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => { dispatch(checkSelectType(0)) }}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('exchange_1')} {t('header_22')}</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        {/* <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => { FuturesOrderHistory(); setChecksym(false) }}>{t('market_0')}</div>
                        </div> */}
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-3'>
                        <div className='history-right-bottom-3-1'>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_6')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalVolume.toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_13')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum(totalPnl.toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('history_14')}</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum((Math.abs(totalFee) * (personal_fee)).toFixed(4))}</span>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>Sum</div>
                                </div>
                                <div className='history-right-bottom-3-1-box-txt'>
                                    <span>{changeNum((totalPnl + totalFee * (1 - personal_fee)).toFixed(4))}</span>
                                    <span style={{ fontSize: "14px", fontWeight: "400", paddingBottom: "3px" }}>USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1 first'>{t('history_8')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_9')} (USDT)</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_21')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_10')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_11')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_12')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                futuressum && futuressum.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    futuressum.map(function (a, i) {
                                        return (
                                            <SpotDetailItem key={i} num={i} item={a} setSelectsym={setSelectsym} selectsym={selectsym} checksym={checksym} type={"futures"} darkname={darkname} OrderHistory={futuresOrderHistory} handleButtonClick={handleButtonClick} personal_fee={personal_fee} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FuturesFee({ darkname, dark, SelectType }) {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [feeData, setFeeDate] = useState([])

    let dispatch = useDispatch();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FundingFee(token)
        }
    }, [selectedDate, selectedDate1])

    const FundingFee = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/funding-fee`, {
            params: {
                startTime: selectedDate.getTime(),
                endTime: selectedDate1.getTime(),
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data.data
            if (tmp) setFeeDate(tmp)
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 108, "msg": error }))
        })
    }

    const [page, setPage] = useState(1)
    let filterfeeData = feeData.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_24')}</div>
                <p className='history-right-top-2'>{t('header_25')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(0))}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('exchange_1')} {t('header_24')}</div>
                        <div style={{ textAlign: "left", fontSize: "14px" }}>{t('history_22')}</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        {/* <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => setSearchbtn(e => !e)}>{t('market_0')}</div>
                        </div> */}
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1'>{t('history_23')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_24')}</div>
                            <div className='history-right-bottom-4-top-1'>{t('history_25')}</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                feeData && feeData.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filterfeeData.map(function (a, i) {
                                                return (
                                                    <FeeItem key={i} item={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={feeData} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FeeItem({ item, darkname }) {
    let arr = item.instId.split('-')
    const date = UTCTimestamp(new Date(Number(item.ts)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <div className={`history-right-bottom-4-bottom-2-2 ${darkname}`}>
            <div className='history-right-bottom-4-bottom-2-1'>{formattedDate}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{arr[0]}USDT</div>
            <div className={`history-right-bottom-4-bottom-2-1 ${Number(item.balChg) >= 0 ? "green" : "red"}`}>{changeNum(Math.abs(Number(item.balChg)).toFixed(8))}</div>
        </div>
    )
}

function FuturesFund({ darkname, dark, SelectType }) {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [dateBtn, setDateBtn] = useState(0)
    const [fundData, setFundDate] = useState([])
    const [searchbtn, setSearchbtn] = useState(false)

    let dispatch = useDispatch();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            InsuranceFund(token)
        }
    }, [searchbtn])

    const InsuranceFund = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/insurance-fund`, {
            params: {
                date: selectedDate.getTime()
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data.data
            if (tmp) setFundDate(tmp)
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 109, "msg": error }))
        })
    }

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>Insurance Fund</div>
                <p className='history-right-top-2'>Insurance Fund</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={SelectType === 1 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(1))}>{t('exchange_0')}</div>
                    <div className={SelectType === 0 ? `history-right-bottom-1-btn current ${darkname}` : `history-right-bottom-1-btn ${darkname}`} onClick={() => dispatch(checkSelectType(0))}>{t('exchange_1')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>OKX Insurance Fund History</div>
                        <div style={{ textAlign: "left", fontSize: "14px" }}>Daily Insurance Fund History</div>
                    </div>
                    <div className='history-right-bottom-2-2'>
                        <div className={`history-right-bottom-2-2-date ${darkname}`}>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setDateBtn(0) }}
                                />
                            </div>
                            <p>-</p>
                            <div className='history-right-bottom-2-2-data-1'>
                                {
                                    dark
                                        ?
                                        <img src='/img/ico_calendar_dark.svg' className='history-right-bottom-2-2-data-img'></img>
                                        :
                                        <img src='/img/ico_calendar.svg' className='history-right-bottom-2-2-data-img'></img>
                                }
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => { setSelectedDate1(date); setDateBtn(0) }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='history-right-bottom-2-2-search' onClick={() => setSearchbtn(e => !e)}>Search</div>
                        </div>
                    </div>
                    <div className={`history-right-bottom-2-3 ${darkname}`}>
                        <div className={dateBtn === 1 ? "on" : ""} onClick={() => {
                            setDateBtn(1);
                            setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date())
                        }}>{t('history_2')}</div>
                        <div className={dateBtn === 2 ? "on" : ""} onClick={() => {
                            setDateBtn(2);
                            setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                        }}>{t('history_3')}</div>
                        <div className={dateBtn === 3 ? "on" : ""} onClick={() => {
                            setDateBtn(3);
                            setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                        }}>{t('history_4')}</div>
                        <div className={dateBtn === 4 ? "on" : ""} onClick={() => {
                            setDateBtn(4);
                            setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                        }}>{t('history_5')}</div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`}>
                            <div className='history-right-bottom-4-top-1'>Date</div>
                            <div className='history-right-bottom-4-top-1'>Market</div>
                            <div className='history-right-bottom-4-top-1'>Amount</div>
                        </div>
                        <div className={`history-right-bottom-4-bottom ${darkname}`}>
                            {
                                fundData && fundData.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    fundData.map(function (a, i) {
                                        return (
                                            <FundItem key={i} item={a} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FundItem({ item }) {
    const date = UTCTimestamp(new Date(Number(item.ts)));
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <div className='history-right-bottom-4-bottom-2'>
            <div className='history-right-bottom-4-bottom-2-1'>{formattedDate}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{item.ccy}</div>
            <div className='history-right-bottom-4-bottom-2-1'>{changeNum(item.bal)}({item.balChg})</div>
        </div>
    )
}

export default History;