let img = [
    "1INCH", "1000BONK", "1000FLOKI", "1000LUNC", "1000PEPE", "1000SHIB", "1000XEC", "AAVE", "ACA", "ACH", "ACM", "ADA", "ADADOWN", "ADAUP", "ADX", "AERGO", "AEUR", "AGIX", "AGLD", "AIDOGE", "AION", "AKITA", "AKRO", "ALCX", "ALGO", "ALICE", "ALPACA", "ALPHA",
    "ALPINE", "AMB", "AMP", "ANC", "ANKR", "ANT", "APE", "API3", "APM", "APT", "AR", "ARB", "ARDR", "ARG", "ARK", "ARKM", "ARPA", "ASR", "AST", "ASTR", "ATA", "ATM", "ATOM", "AUCTION", "AUD", "AUDIO", "AUTO", "AVA", "AVAX", "AXS", "AZY", "BABYDOGE", "BADGER",
    "BAKE", "BAL", "BAND", "BAR", "BAT", "BCC", "BCH", "BEAMX", "BEL", "BETA", "BETH", "BICO", "BIFI", "BIGTIME", "BLOK", "BLUEBIRD", "BLER", "BLZ", "BNB", "BNBDOWN", "BNBUP", "BUT", "BUX", "BOND", "BONE", "BORA", "BORING", "BRWL", "BSV", "BSW", "BTC",
    "BTCDOM", "BTCDOWN", "BTCST", "BTCUP", "BTG", "BTM", "BTS", "BTT", "BTTC", "BULR", "BURGER", "BUSD", "BZZ", "C98", "CAKE", "CAPO", "CDT", "CEEK", "CEL", "CELO", "CELR", "CETUS", "CFG", "CFX", "CGL", "CHESS", "CHR", "CHZ", "CITY", "CKB", "CLV", "COCOS",
    "COMBO", "COMP", "CONV", "CORE", "COS", "COTI", "COVER", "CQI", "CREAM", "CRO", "CRV", "CSPR", "CTC", "CTK", "CTSI", "CTXC", "CVC", "CVP", "CVX", "CYBER", "DAI", "DAO", "DAR", "DASH", "DATA", "DCR", "DEFI", "DEGO", "DENT", "DEP", "DEXE", "DF", "DGB",
    "DHT", "DIA", "DNT", "DOCK", "DODO", "DODOX", "DOGE", "DOME", "DORA", "DOSE", "DOT", "DREP", "DUSK", "DYDX", "EDU", "EGLD", "ELF", "ELON", "EM", "ENJ", "ENS", "EOS", "EPX", "ERN", "ETC", "ETH", "ETHDOWN", "DTHUP", "ETHW", "EUR", "EURT", "FAME", "FARM",
    "FD", "FDUSD", "FET", "FIDA", "FIL", "FIO", "FIRO", "FIS", "FITFI", "FLM", "FLOKI", "FLOW", "FLR", "FLUX", "FOOTBALL", "FOR", "FORTH", "FRONT", "FSN", "FTM", "FTT", "FUN", "FXS", "GAL", "GALA", "GALFT", "GARI", "GAS", "GBP", "GEAR", "GF", "GET", "GHST",
    "GLM", "GLMR", "GMT", "GMX", "GNO", "GNS", "GOAL", "GODS", "GOG", "GRT", "GTO", "GXS", "HARD", "HBAR", "HC", "HFT", "HIGH", "HIVE", "HNT", "HOOK", "HOT", "ICN", "ICP", "ICX", "ID", "IDEX", "IGU", "ILV", "IMX", "INJ", "IOST", "IOTA", "IOTX", "IQ",
    "JASMY", "JOE", "JPG", "JST", "JUV", "KAN", "KAR", "KAS", "KAVA", "KCAL", "KDA", "KEY", "KINE", "KISHU", "KLAY", "KMD", "KNC", "KONO", "KP3R", "KSM", "LAMB", "LAT", "LAZIO", "LBR", "LDO", "LEASH", "LEO", "LET", "LEVER", "LHINU", "LINA", "LING", "LINK",
    "LINKDOWN", "LINKUP", "LIT", "LITH", "LOKA", "LON", "LOOKS", "LOOM", "LPT", "LQTY", "LRC", "LSK", "LTC", "LTO", "LUNA", "LUNA2", "LUNC", "MAGIC", "MANA", "MASK", "MATIC", "MAV", "MBL", "MBOX", "MC", "MCO", "MDA", "MDT", "MDX", "MEME", "MENGO", "METIS",
    "MFT", "MILO", "MINA", "MIR", "MKR", "MLN", "MOB", "MOVEZ", "MOVR", "MRST", "MTL", "MULTI", "MXC", "MYRIA", "NBS", "NBT", "NEAR", "NEBL", "NEO", "NEXO", "NFT", "NKN", "NMR", "NTRN", "NULS", "NYM", "OAS", "OAX", "OCEAN", "OG", "OGN", "OKB", "OKT", "OM",
    "OMG", "OMI", "OMIN", "ONE", "ONG", "ONT", "OOKI", "OP", "ORB", "ORBS", "ORDI", "origin", "ORN", "OSMO", "OXT", "PAX", "PAXG", "PCI", "PENDLE", "PEOPLE", "PEPE", "PERL", "PERP", "PHA", "PHB", "PICKLE", "PIT", "PIVX", "PLA", "PNT", "POLS", "POLYDOGE",
    "POLYX", "POND", "POR", "PORTO", "POWR", "PROM", "PROS", "PRQ", "PSG", "PSTAKE", "PUNDIX", "PYR", "PYTH", "QI", "QKC", "QNT", "QOM", "QTUM", "QUICK", "RACA", "RAD", "RADAR", "RARE", "RAY", "RDNT", "REEF", "REI", "REN", "REP", "REQ", "REVV", "RGT",
    "RIF", "RIO", "RLC", "RNDR", "RON", "ROSE", "RPL", "RSR", "RSS3", "RUNE", "RVN", "SALT", "SAMO", "SAND", "SANTOS", "SC", "SCRT", "SD", "SEI", "SFP", "SHIB", "SIS", "SKEB", "SKL", "SLP", "SNGLS", "SNM", "SNT", "SNX", "SOL", "SPELL", "SPURS", "SRM", "SSV",
    "SSWP", "STARL", "STC", "STEEM", "STEEH", "STG", "STMX", "STORJ", "STPT", "STRAT", "STRAX", "STX", "SUB", "SUI", "SUN", "SUPER", "SUSHI", "SWEAT", "SWFTC", "SXP", "SYN", "SYS", "T", "TAKI", "TAMA", "TCT", "TFUEL", "THETA", "THG", "TIA", "TKO", "TLM",
    "TNT", "TOKEN", "TOMO", "TON", "TORN", "TRA", "TRB", "TROY", "TRU", "TRX", "TUP", "TURBO", "TUSD", "TVK", "TWT", "UFT", "UMA", "UNFI", "UNI", "USDC", "USDP", "USDT", "USDTTRY", "UST", "USTC", "UTK", "VANRY", "VELA", "VELO", "VELODROME", "VEN", "VET",
    "VGX", "VIB", "VIC", "VIDT", "VITE", "VOXEL", "VRA", "VSYS", "VTHO", "WAN", "WAVES", "WAXP", "WBETH", "WBTC", "WGRT", "WIFI", "WIN", "WING", "WLD", "WNCG", "WNXM", "WOO", "WRX", "WSB", "WSM", "WTC", "WXT", "XAUT", "XCH", "XEC", "XEM", "XETA", "XLM", "XMR",
    "XNO", "XPR", "XRP", "XRPDOWN", "XRPUP", "XTZ", "XVG", "XVS", "YFI", "YFII", "YGG", "YOYO", "ZBC", "ZEC", "ZEN", "ZIL", "ZKS", "ZRX", "BNX", "IRIS", "BNT", "ETHUP", "JTO", "1000SATS", "ACE", "1000RATS", "BLUR", "GTC", "HIFI", "AVIVE", "SATS", "STETH", "GFT",
    "CQT", "OMN", "AEVO", "AI", "ALT", "AXL", "BOME", "BONK", "DMAIL", "DYM", "ETHFI", "GPT", "ICE", "JUP", "MANTA", "MAVIA", "MYRO", "NFP", "ONDO", "PDA", "PIXEL", "PORTAL", "RONIN", "SLN", "STRK", "WIF", "XAI", "ZETA", "ZK", "ARTY", "ENA", "W", "VENOM"
]

export default img;