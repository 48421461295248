import './App.css';
import './Nav/nav.css'
import { Routes, Route, Navigate } from 'react-router-dom'
// Common
import Main1 from './Pages/common/C_main/main1'
import Main2 from './Pages/common/C_main/main2'
import API from './api'
import Market from './Pages/market'
import Login from './Pages/login'
import Regist from './Pages/regist'
import Dashboard from './Pages/dashboard'
import OTP from './Pages/otp'
// Binance
import BSpot from './Pages/Binance/Bspot'
import BFutures from './Pages/Binance/Bfutures'
import BHistory from './Pages/Binance/Bhistory'
import BWallet from './Pages/Binance/Bwallet'
// OKX
import Spot from './Pages/OKX/spot'
import Futures from './Pages/OKX/futures'
import History from './Pages/OKX/history'
import Wallet from './Pages/OKX/wallet'

import GOEXS_header from './Nav/goexs_header'
import GOEXS_footer from './Nav/goexs_footer'

import ErrorBoundary from './Pages/ErrorBoundary'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { checkAPI_Address, checkGuide } from './store';

let background_time = false
function App() {
    useEffect(() => {
        dispatch(checkAPI_Address(process.env.REACT_APP_API))
        setTimeout(() => {
            background_time = true
        }, 600000)
    }, [])


    // 백그라운드에서 포그라운드로 전환될 때, 새로고침
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && background_time) {
                window.location.reload()
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    let API_Address = useSelector((state) => { return state.API_Address.value })
    let SelectType = useSelector((state) => { return state.SelectType.value })
    let login = useSelector((state) => { return state.login.value });
    let Guide = useSelector((state) => { return state.Guide.value });

    let dispatch = useDispatch();
    const { t } = useTranslation();

    console.error = (message) => {
    };

    useEffect(() => {
        if (login) {
            let objString = localStorage.getItem("guide")
            if (objString) {
                dispatch(checkGuide(true))
            }
            else {
                dispatch(checkGuide(false))
            }
        }
    }, [login])

    const Guide_Cancel = () => {
        localStorage.setItem('guide', true)
        dispatch(checkGuide(true))
    }

    return (
        <>
            <div className="App">
                {/* {
                    login && !Guide && API_Address == "rf"
                        ?
                        <div className="header-alert">
                            <div className="header-alert-box">
                                <div className="header-alert-box-left">
                                    <div className="header-alert-box-img"><img src="/img/logo/android-icon-36x36.png" /></div>
                                    <div className="header-alert-box-text">
                                        <div className="header-alert-box-text-1">{t('header_63')}</div>
                                        <div className="header-alert-box-text-2">{t('header_64')}</div>
                                    </div>
                                </div>
                                <div className="header-alert-box-right">
                                    <div className="header-alert-box-cancel" onClick={() => Guide_Cancel()}>{t('header_65')}</div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                } */}
                <div style={{ position: "relative" }}>
                    <GOEXS_header />
                    <API />
                    <ErrorBoundary>
                        <Routes>
                            {/* <Route path="/" element={<Main1 />} /> */}
                            <Route path="/" element={<Main2 />} />

                            <Route path="/market" element={<Market />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/regist" element={<Regist />} />

                            <Route path="/spot/okx/:symbol" element={<Spot />} />
                            <Route path="/spot/binance/:symbol" element={<BSpot />} />

                            <Route path="/futures/okx/:symbol" element={<Futures />} />
                            <Route path="/futures/binance/:symbol" element={<BFutures />} />

                            <Route path="/history" element={SelectType === 0 ? <History /> : <BHistory />} />
                            <Route path="/wallet" element={SelectType === 0 ? <Wallet /> : <BWallet />} />

                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/otp" element={<OTP />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </ErrorBoundary>
                    <GOEXS_footer />
                </div>
            </div>
        </>
    );
}

export default App;
