import '../../css/wallet.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage } from '../../../store';
import { useTranslation } from "react-i18next";

function OKX_Gift({ darkname }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [tab, setTab] = useState(false)
    const [tab1, setTab1] = useState(0)
    const [isToggled1, setIsToggled1] = useState(false);

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const [code, setCode] = useState(['', '', '', '', '', '']); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector('input');
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector('input');
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_39')}</div>
                <p className='history-right-top-2'>{t('header_40')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_167')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_168')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_169')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_170')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_171')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_172')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_173')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_174')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_175')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('exchange_1')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_176')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>-</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_177')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>40</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_178')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>0</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_179')}</div>
                    <div className={`wallet-right-form-4-9 ${darkname}`}>
                        <textarea className='wallet-right-form-4-9-1' cols="30" rows="10" maxlength="100"></textarea>
                        <div className='wallet-right-form-4-9-2'>(<span>0</span> / 100)</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_180')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_181')}
                    </div>
                    <div className='wallet-right-form-8'>
                        {code.map((digit, index) => (
                            <div className={`wallet-right-form-8-1 ${darkname}`}>
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`wallet-right-form-8-2 ${darkname}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`}>{t('header_39')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_182')}</div>
                    <div className='wallet-right-form-7-4'>
                        <div className={tab1 === 0 ? `wallet-right-form-7-4-1 current ${darkname}` : `wallet-right-form-7-4-1 ${darkname}`} onClick={() => setTab1(0)}>{t('wallet_183')}</div>
                        <div className={tab1 === 1 ? `wallet-right-form-7-4-1 current ${darkname}` : `wallet-right-form-7-4-1 ${darkname}`} onClick={() => setTab1(1)}>{t('wallet_184')}</div>
                    </div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_185')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_186')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_187')}</div>
                            <div className='wallet-right-form-7-3-top-6'>{t('wallet_188')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
                                <div className='wallet-right-form-7-3-bottom-5'>2023-10-25 14:54:32</div>
                                <div className='wallet-right-form-7-3-bottom-5'>-</div>
                                <div className='wallet-right-form-7-3-bottom-5'>-</div>
                                <div className='wallet-right-form-7-3-bottom-5'>-</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OKX_Gift;