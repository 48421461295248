import '../css/futures.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, check_Error } from '../../store';
import base64 from 'base-64';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../ErrorBoundary'
import isEqual from 'lodash/isEqual';
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../common/function"

import BFsection1 from './Bfutures/BFsection1';
import BFsection4 from './Bfutures/BFsection4';
import BFsection8 from './Bfutures/BFsection8';
import BFsection7 from './Bfutures/BFsection7';
import BFsection6 from './Bfutures/BFsection6';

let data = []
let preArr = []
let arr = []
let pre_symbol = ""

function BFutures() {
    const { t } = useTranslation();
    let BinanceF = useSelector((state) => { return state.Binance_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Binance_position = useSelector((state) => { return state.Binance_position });
    let Binance_liquidation = useSelector((state) => { return state.Binance_liquidation });
    let Alert = useSelector((state) => { return state.Alert });
    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });

    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab3, setTab3] = useState(true)
    const [tab4, setTab4] = useState(1)
    const [tab5, setTab5] = useState(0)
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)
    const [speedorder, setSpeedorder] = useState([0, 0])
    const [fwallet, setFwallet] = useState([])
    const [current, setCurrent] = useState('0');

    const [exinfo, setExinfo] = useState([])
    const [toalexinfo, setTotalExinfo] = useState([])
    const [positionAmount, setPositionAmount] = useState([false, ""])
    const [positionData, setPositionData] = useState([]);

    const [max, setMax] = useState(4)
    const [maxCnt, setMaxCnt] = useState(true)
    const [firstmargin, setFirstmargin] = useState([])
    const [maxPosition, setMaxPosition] = useState(0)
    const [marginbtn, setMarginbtn] = useState(0)
    const [marginbtn1, setMarginbtn1] = useState(1)

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [checkisPlaying, setCheckisPlaying] = useState(false)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [checkCurrentPosition, setCheckCurrentPosition] = useState(false)
    const [btnbtn, setBtnbtn] = useState(0);
    const [mmr, setMmr] = useState(0)

    const [markPrice, setMarkPrice] = useState([])
    const [nextTime, setNextTime] = useState("00:00:00")
    const [close, setClose] = useState(false)
    const [fundingFee, setFundingFee] = useState(0)
    const [positionDirect, setPositionDirect] = useState(0)
    const [risk_balance, setRisk_balance] = useState(0)

    let USDTSymbolF = BinanceF ? BinanceF : [];
    dispatch(checkSelectType(1))

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    useEffect(() => {
        setMaxCnt(true)
    }, [symbol])

    USDTSymbolF.map(function (a, i) {
        if (a.symbol === symbol) {
            if (maxCnt) {
                setMax(a.point)
                setMaxCnt(false)
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        const handleResize = () => {
            // if (window.innerWidth <= 1550) setTab(false)
            // else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) setWindowtypeM(true)
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    const playAudio = () => {
        setIsPlaying(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying1(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0.00000000001) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "USDT" : ""} | Binance`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "USDT" : ""} | Binance`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        setCurrentPrice(0.00000000001)
    }, [symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesInstruments(token)
        }
    }, [symbol, marginbtn])

    useEffect(() => {
        let nextArr = []
        let cnt = false

        const objString = localStorage.getItem('token');
        if (objString != null && Binance_position) {
            arr = Binance_position

            // arr.sort((a, b) => {
            //     if (a.symbol > b.symbol) return 1;
            //     if (a.symbol < b.symbol) return -1;
            // })

            arr.map(function (a, i) {
                if (symbol == a.symbol) cnt = true

                nextArr.push({ symbol: a.symbol, size: a.positionAmt })
            })

            setCheckCurrentPosition(cnt)

            let ArraysEqual = isEqual(preArr, nextArr);

            if (!ArraysEqual) {
                if (Alert[1] == "ON") {
                    playAudio()
                }
            }

            preArr = nextArr
            setPositionData(arr)
        }
    }, [Binance_position, symbol])



    useEffect(() => {
        let cnt = 0
        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                if (symbol == positionData[i].symbol) {
                    let tmp = positionData[i]
                    cnt = 1
                    setFundingFee(Math.abs(tmp.notional))
                    setPositionDirect(Number(tmp.positionAmt))
                    setRisk_balance(Math.abs(Number(tmp.notional) / Number(tmp.leverage)))
                }
            }
            if (cnt === 0) {
                setFundingFee(0)
                setPositionDirect(0)
                setRisk_balance(0)
            }
        }
    }, [positionData])

    useEffect(() => {
        pre_symbol = symbol
        let socket = new WebSocket(`wss://fstream.binance.com/stream?streams=${symbol.toLowerCase()}@markPrice@1s`);
        socket.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp) {
                    setMarkPrice(tmp.data)

                    let date = Math.floor((tmp.data.T - new Date()) / 1000)
                    let formattedDate = `${padZero(Math.floor(date / (60 * 60)))}:${padZero(Math.floor((date / 60) % 60))}:${padZero(Math.floor(date % 60))}`;
                    setNextTime(formattedDate)
                }
            }
        }
        socket.onclose = function (event) {
            if (pre_symbol == symbol) {
                setClose(e => !e)
            }
        };
        socket.error = function (error) {
            window.location.reload()
        }
        return () => {
            socket.close();
        }
    }, [symbol, close])

    let cnt_liq = 0

    useEffect(() => {
        if (cnt_liq > 0) {
            if (Alert[1] == "ON") {
                playAudio1()
            }
        }
        cnt_liq = 1
    }, [Binance_liquidation])

    useEffect(() => {
        if (Binance_wallet_futures.length > 0) {
            setCurrent(Binance_wallet_futures[0].availableBalance)
            setFwallet(Binance_wallet_futures[0])
        }
    }, [Binance_wallet_futures])

    useEffect(() => {
        ExchangeInformation()
    }, [symbol])

    const ExchangeInformation = async () => {
        await axios.get('https://fapi.binance.com/fapi/v1/exchangeInfo'
        ).then((response) => {
            let tmp = response.data.symbols
            if (tmp) {
                tmp.map(function (a, i) {
                    if (a.symbol == symbol) {
                        setExinfo(a.filters)
                    }
                })
                setTotalExinfo(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 46, "msg": error }))
        })
    }

    useEffect(() => {
        if (firstmargin) {
            let mar = 0
            let mmr = 0
            for (let i = 0; i < firstmargin.length; i++) {
                if (marginbtn1 <= firstmargin[i].initialLeverage) {
                    mar = firstmargin[i].notionalCap
                    mmr = Number(firstmargin[i].maintMarginRatio)
                }
            }
            setMaxPosition(mar)
            setMmr(mmr)
        }
    }, [firstmargin, marginbtn1])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesLever(token)
        }
    }, [symbol])

    const FuturesLever = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/leverage`, {
            params: {
                symbol: symbol
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                if (tmp[0].brackets && tmp[0].brackets.length > 0) {
                    setFirstmargin(response.data.data[0].brackets)
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 47, "msg": error }))
        })
    }

    const FuturesInstruments = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/position`, {
            params: {
                symbol: symbol
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data.symbolData
            let tmp1 = response.data.data.positionData

            if (tmp && tmp[0]) {
                setMarginbtn(tmp[0].marginType == "cross" ? 0 : 1)
                setMarginbtn1(tmp[0].leverage)
            }
            if (tmp1) {
                let cnt = false
                for (let i = 0; i < tmp1.length; i++) {
                    if (symbol == tmp1[i].symbol) {
                        cnt = true
                    }
                }
                setCheckCurrentPosition(cnt)
                setPositionData(tmp1)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 48, "msg": error }))
        })
    }

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    return (
        <div className={`futures ${darkname}`}
        // style={API_Address == "dev" ? { paddingLeft: "50px", width: "calc(100% - 50px)" } : {}}
        >
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.2.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.5.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                windowtype
                    ?
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <BFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className={tab ? 'futures-section2' : 'futures-section2-check'}>
                            <div className='futures-section3'>
                                <div className='futures-section5'>
                                    <ErrorBoundary>
                                        <BFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <BFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} />
                                    </ErrorBoundary>
                                </div>
                                <ErrorBoundary>
                                    <BFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} toalexinfo={toalexinfo} setCheckisPlaying={setCheckisPlaying} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} />
                                </ErrorBoundary>
                            </div>
                            <ErrorBoundary>
                                <BFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} mmr={mmr} risk_balance={risk_balance} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    :
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <BFsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className='futures-section9'>
                            <ErrorBoundary>
                                <BFsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} exinfo={exinfo} max={max} maxPosition={maxPosition} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} nextTime={nextTime} fundingFee={fundingFee} positionDirect={positionDirect} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <BFsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} exinfo={exinfo} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} toalexinfo={toalexinfo} setCheckisPlaying={setCheckisPlaying} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} />
                            </ErrorBoundary>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <BFsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} exinfo={exinfo} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <BFsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} exinfo={exinfo} positionAmount={positionAmount} max={max} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} fwallet={fwallet} current={current} btnbtn={btnbtn} setBtnbtn={setBtnbtn} mmr={mmr} risk_balance={risk_balance} />
                                </ErrorBoundary>
                            </div>

                            <div style={{ padding: "5px 5px 0" }}>
                                <div className='futures-section4-top-9 display-none-700-ok'>
                                    <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                                    <div className='futures-section4-top-9-2 green'>{fwallet.balance ? changeNum((risk_balance * 100 / (Number(fwallet.balance) + Number(fwallet.crossUnPnl))).toFixed(2)) : "0.00"}%</div>
                                    <div className='futures-section4-top-9-2'>%</div>
                                </div>
                                <div className='futures-section4-top-10 display-none-700-block'>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum((Number(fwallet.balance) + Number(fwallet.crossUnPnl)).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum(Number(fwallet.balance).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.availableBalance ? changeNum(Number(fwallet.availableBalance).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    {/* <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>Maintenance Margin</div>
                                        <div className='futures-section4-top-10-1-2'>-</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default BFutures;