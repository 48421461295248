import { useSelector } from 'react-redux';
import '../css/modal.css'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

function Message({ message }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";
    const [buyModal, setBuyModal] = useState(false);

    useEffect(() => {
        if (message.code) {
            setBuyModal(true)
        }
    }, [message]);

    return (
        <>
            {
                buyModal
                    ?
                    <>
                        <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(3px)" }}></div>
                        <div className={`message-modal ${darkname}`}>
                            <div className='message-modal-top'>
                                <div className='message-modal-top-1'>{message.type === 0 ? message.code >= 0 ? `${t('message_1')}` : `${t('message_2')}` : message.code === 0 ? `${t('message_1')}` : `${t('message_2')}`}</div>
                                {/* <div className='message-modal-top-2'>close</div> */}
                            </div>
                            <div className='message-modal-2'>{message.msg}</div>
                            <div className='message-modal-btn' onClick={() => setBuyModal(false)}>{t('message_3')}</div>
                        </div>
                    </>
                    :
                    ""
            }

        </>
    )
}

export default Message;