import '../../css/wallet.css'
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage } from '../../../store';
import { useTranslation } from "react-i18next";

function Common_Deposit({ darkname }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [tab, setTab] = useState(false)

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_31')}</div>
                <p className='history-right-top-2'>{t('header_32')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>FAQ</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_20')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        1. {t('wallet_21')}<br />
                                        2. {t('wallet_22')}<br />
                                        3. {t('wallet_23')}<br />
                                        4. {t('wallet_24')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_26')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_27')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_28')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_29')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_30')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_31')} <br />
                                        1. {t('wallet_32')}<br />
                                        2. {t('wallet_33')}<br /><br />

                                        {t('wallet_34')}<br />
                                        - {t('wallet_35')}<br />
                                        - {t('wallet_36')}<br />
                                        - {t('wallet_37')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_38')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_39')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_40')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_41')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_42')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_43')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_44')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_45')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_46')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_47')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_48')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_49')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_50')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_51')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_52')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                        {t('wallet_225')}</div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>

                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_53')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t('wallet_54')}`} />
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_57')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select
                                value={selectedOption}
                            >
                                <option value="">{t('wallet_58')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <p className='wallet-right-form-5'>
                    {t('wallet_59')}<br />
                    {t('wallet_60')}
                </p>
                <div className='wallet-right-form-6'>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_61')}</div>
                        <div className='wallet-right-form-4-7'>
                            <div className='wallet-right-form-4-7-1'>
                                <div style={{ height: "128px" }}></div>
                            </div>
                        </div>
                        <div className='wallet-right-form-4-8'>
                            <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                        </div>
                    </div>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_63')}</div>
                        <div className='wallet-right-form-4-7-no'>
                            <div className='wallet-right-form-4-7-1-no'>
                                <div>{t('wallet_64')}</div>
                            </div>
                        </div>
                        <div className='wallet-right-form-4-8'>
                            <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_65')}</div>
                </div>
                {/* <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>Caution</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                    </div>
                </div> */}
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_67')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Common_Deposit;