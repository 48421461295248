import "./css/spot.css";
import React, { useEffect, useRef, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "../store";
import base64 from 'base-64';

let cnt = 0;
let cnt_chart = 0;
let langList = ["ar", "zh", "cs", "da_DK", "nl_NL", "en", "et_EE", "fr", "de", "el", "he_IL", "hu_HU", "id_ID", "it", "ja", "ko", "fa", "pl", "pt", "ro", "ru", "sk_SK", "es", "sv", "th", "tr", "vi", "no", "ms_MY", "zh_TW"];

function FutureChart({ exchange, market }) {
  const objString = localStorage.getItem("token");
  let dec = "";
  if (objString) {
    const obj = JSON.parse(objString);
    let token = obj.value;
    let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
    dec = JSON.parse(base64.decode(payload));
  }

  let dark = useSelector((state) => { return state.Darkmode.value; });
  let Languages = useSelector((state) => { return state.Languages.value; });

  const [chart_check, setChart_check] = useState(false);

  useEffect(() => {
    cnt_chart = 0;
  }, [exchange, market, dark, Languages]);

  if (cnt_chart === 1) {
    setChart_check(true);
  }
  cnt_chart += 1;

  let { symbol } = useParams();
  let dispatch = useDispatch();
  let arr = [];
  if (exchange === "okx") {
    arr = symbol.split("-");
  } else {
    arr = symbol.split("USDT");
  }
  let lang = Languages.split("-");

  useEffect(() => {
    cnt = 0;
    const objString = localStorage.getItem("token");
    if (objString != null) {
      const obj = JSON.parse(objString);
      if (Date.now() > obj.expire) {
        localStorage.removeItem("token");
        dispatch(checkLogin(false));
      } else {
        dispatch(checkLogin(true));
      }
    } else {
      dispatch(checkLogin(false));
    }
  }, [symbol, dark]);

  

  // let first_symbol = ""
  // let first_dark = ""

  // useEffect(() => {
  //   first_symbol = arr[0]
  //   first_dark = dark ? "dark" : "light"
  // }, [])

  // const [cnt_iframe, setCnt_iframe] = useState(false)
  // const iframe = document.getElementById('iframe');

  // if (iframe && !cnt_iframe) {
  //   setCnt_iframe(true)
  // }

  // useEffect(() => {
  //   if (iframe && iframe.contentWindow) {
  //     iframe.contentWindow.postMessage({ symbol: arr[0] }, '*');
  //     iframe.contentWindow.postMessage({ mode: dark ? "dark" : "light" }, '*');
  //   }
  // }, [cnt_iframe, symbol, dark])

  return (
    <>
      {
        chart_check
          ?
          // <iframe src={`https://chart.alp.kr?symbol=${first_symbol}&exid=${exchange}&mode=${first_dark}&lang=${lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en'}&type=${market === "spot" ? 1 : 2}&id=${dec.userId}`}
          //   style={{ width: "100%", height: "100%", border: "none" }} id="iframe" />
          <iframe src={`https://chart.alp.kr?symbol=${arr[0]}&exid=${exchange}&mode=${dark ? "dark" : "light"}&lang=${lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en'}&type=${market === "spot" ? 1 : 2}&id=${dec.userId}`}
            style={{ width: "100%", height: "100%", border: "none" }} />
          // <iframe src={`https://chart.rf-rev.com?symbol=${arr[0]}&exid=${exchange}&mode=${dark ? "dark" : "light"}&lang=${lang && lang[0] && langList.includes(lang[0]) ? lang[0] : 'en'}&type=${market === "spot" ? 1 : 2}&id=${dec.userId}`}
          //   style={{ width: "100%", height: "100%", border: "none" }} />
          :
          ""
      }
    </>
  );
}

export default FutureChart;